import React, { Fragment, useState, useEffect } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { isEmptyOrNull, isEmpty, isNull, isWebsiteBusy, getErrorMsg } from 'helpers';
import { Link } from 'react-router-dom';
import { WPSButton } from 'styles/layout/buttons';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGlobalInfoMsg, setGlobalSuccessMsg } from 'store/global/globalActions';
import {
  issueWebsiteAcmCertificate,
  deleteWebsiteCdnCache,
  deployWebsiteCdnProxy,
  deleteWebsiteCdnProxy,
  createWebsiteCloudflareZone,
  connectWebsiteCloudflareZone,
  deleteWebsiteCloudflareZone,
} from 'store/website/websiteActions';
import useConfirm from 'hooks/useConfirm';
import WebsiteHelper from 'helpers/website';
import StringHelper from 'helpers/string';
import DialogHelper from 'helpers/dialog';
import ArrayHelper from 'helpers/array';
import CloudflareHelper from 'helpers/cloudflare';
import useModal from 'hooks/useModal';
import CloudflareService from 'services/cloudflare';
import env from 'config/env';

//cdn css file
import './cssFiles/cdn.css';
import JsxHelper from 'helpers/jsx';

const Cdn = ({ website }) => {
  useTitle('Website CDN');

  const history = useHistory();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const modalDialog = useModal();

  const registrableDomain = website.registrable_domain;
  const cdnDeploymentError = website.metadata.cdn_deployment_error;

  const initialCardOptions = {
    title: '',
    name: '',
    desc: '',
    icon: '',
    btnText: '',
    btnDisabled: false,
    toolTipText: '',
    hide: false,
    type: null,
    btn1Click: null,
    btn2Click: null,
  };

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [displayCards, setDisplayCards] = useState(true);
  // traditional
  const [cacheCdnHost, setCacheCdnHost] = useState('cdn');
  const [cacheCardOptions, setCacheCardOptions] = useState(initialCardOptions);
  // proxy
  const [proxyCardOptions, setProxyCardOptions] = useState(initialCardOptions);
  // cloudflare
  const [cloudflareCardOptions, setCloudflareCardOptions] = useState(initialCardOptions);
  const [cloudflareAccounts, setCloudflareAccounts] = useState(false);

  const getDefaultCacheCardOptions = () => {
    let options = {...initialCardOptions};
    options.title = 'Traditional CDN';
    options.name = 'cdn-cache';
    options.icon = 'speed';
    options.desc = 'Speed up your site performance. Serve your static content, such as media files and JS/CSS assets, from an AWS CloudFront CDN.';
    options.btnText = 'Get Started';
    options.secondBtn = false;
    options.spinner = false;
    options.btn1Click = handleCacheGetStartedClick;
    options.btn2Click = handleCacheDeleteClick;
    options.toolTipText = `The static content will be served from over 200+ AWS data centers worldwide ensuring high-speed and low-latency performance when your visitors access media files and CSS/JS assets. However, it will be accessed from a different domain than the website's (e.g. cdn.${website.registrable_domain}). Note that this solution will not fully protect your website from DDoS attacks and abusive bots.<br/>It is highly recommended to use Cloudflare or Next-Gen CDN for full protection, unless you know what you are doing.`;
    return options;
  };

  const getDefaultProxyCardOptions = () => {
    let options = {...initialCardOptions};
    options.title = 'Next-Gen CDN';
    options.name = 'cdn-proxy';
    options.icon = 'deployed';
    options.desc = 'Maximize your site load time and security. Serve your entire website almost as serverless via AWS CloudFront CDN.';
    options.btnText = 'Deploy';
    options.spinner = false;
    options.secondBtn = false;
    options.btn1Click = handleProxyDeployClick;
    options.btn2Click = handleProxyDeleteClick;
    options.customClass = 'margin-left-0';
    options.toolTipText = 'The site will be served from over 200+ AWS data centers worldwide ensuring high-speed and low-latency performance for your visitors regardless of their geolocation, resulting in a significantly improved TTFB (Time To First Byte). It also provides protection against denial-of-service attacks and abusive bots.';
    return options;
  };

  const getDefaultCloudflareCardOptions = () => {
    let options = {...initialCardOptions};
    options.title = 'Cloudflare CDN';
    options.name = 'cdn-cloudflare';
    options.icon = 'cloudflare';
    options.desc = 'Integrate your website with Cloudflare\'s global network to speed up your site, protect it from DDoS attacks, and ensure high availability.';
    options.btnText = 'Connect';
    options.secondBtn = false;
    options.spinner = false;
    options.btn1Click = handleCloudflareConnectClick;
    options.btn2Click = handleCloudflareDeleteClick;
    return options;
  };

  useEffect(() => {
    if (WebsiteHelper.isDevMode(website)) {
      setDisplayCards(false);
    } else {
      const hasProxy = initProxyCdn();
      const hasCache = initCacheCdn();
      const hasCloudflare = initCloudflare();
      if (hasProxy) {
        const tooltip = 'Next-Gen CDN is deployed';
        setCacheCardOptions(prev => ({...prev, btnDisabled: true, btnDisabledTip: tooltip }));
        setCloudflareCardOptions(prev => ({...prev, btnDisabled: true,  btnDisabledTip: tooltip }));
      } else if (hasCache) {
        const tooltip = 'Traditional CDN is deployed';
        setProxyCardOptions(prev => ({...prev, btnDisabled: true,  btnDisabledTip: tooltip }));
        setCloudflareCardOptions(prev => ({...prev, btnDisabled: true, btnDisabledTip: tooltip }));
      } else if (hasCloudflare) {
        const tooltip = 'Cloudflare is connected';
        setProxyCardOptions(prev => ({...prev, btnDisabled: true, btnDisabledTip: tooltip }));
        setCacheCardOptions(prev => ({...prev, btnDisabled: true, btnDisabledTip: tooltip }));
      }
    }
    // eslint-disable-next-line
  }, [website]);

  const initProxyCdn = () => {
    const options = getDefaultProxyCardOptions();
    const sslCert = WebsiteHelper.getCdnProxySsl(website);
    const cdnDist = WebsiteHelper.getCdnProxy(website);

    // Do not refresh card settings
    if (creating && !cdnDist && !sslCert) {
      window.logHelper.info('Initialize CDN proxy card is locked: being created.', website);
      return false;
    }
    if (deleting && (cdnDist || sslCert)) {
      window.logHelper.info('Initialize CDN proxy card is locked: being deleted.', website);
      return false;
    }

    // If no SSL then deployment hasn't started
    if (isEmptyOrNull(sslCert)) {
      setProxyCardOptions(options);
      return false;
    }

    // Set default as status "OK"
    options.type = 'status';
    options.statusColor = 'success';
    options.statusTooltip = 'Available';
    options.secondBtn = 'Delete';
    options.btnText = false;
    options.spinner = false;
    options.spinnerWait = false;

    // If CDN does not exist then determine status based on SSL
    if (isEmptyOrNull(cdnDist)) {
      if (sslCert.status === 'VALIDATION_TIMEOUT') {
        options.statusTooltip = 'SSL validation timeout. Please delete the SSL certificate and try again or contact support.';
        options.statusColor = 'danger';
        options.btnText = false; // no button
        options.secondBtn = 'Delete'; // not real delete but revokes SSL
      } else if (sslCert.status === 'PENDING_VALIDATION') {
        options.spinnerText = 'Validating SSL Certificate...';
        options.spinnerWait = true;
        options.spinner = true;
      } else if (sslCert.status !== 'ISSUED') {
        // If not issued then it has failed
        options.statusTooltip = 'SSL validation failed.';
        options.statusColor = 'danger';
        options.btnText = false; // no button
        options.secondBtn = 'Delete'; // not real delete but revokes SSL
      } else if (cdnDeploymentError) {
        // Deployment failed
        options.statusTooltip = `Deployment failed: ${StringHelper.trim(cdnDeploymentError.error, '.')}. ${StringHelper.trim(cdnDeploymentError.solution, '.')}.`;
        options.statusColor = 'danger';
        options.btnText = 'Redeploy';
        options.secondBtn = 'Delete'; // not real delete but revokes SSL
      } else {
        // If issued but CDN deployment hasn't started
        options.spinnerText = 'Configuring CDN...';
        options.spinnerWait = true;
        options.spinner = true;
      }
      setProxyCardOptions(options);
      return true;
    }

    // Otherwise, determine status based on CDN
    if (cdnDist.status === 'initializing') {
      options.spinnerText = 'Deploying CDN...';
      options.spinner = true;
      options.spinnerWait = true;
    } else if (cdnDist.status === 'ok-has-warning') {
      options.statusColor = 'warning';
      options.statusTooltip = cdnDist.status_reason || 'Available (action required).';
    } else if (cdnDist.status !== 'ok') {
      options.statusColor = 'danger';
      options.statusTooltip = cdnDist.status_reason || 'CDN deployment failed.';
    } else {
      // The default is ok
    }
    setProxyCardOptions(options);
    return true;
  }

  const initCacheCdn = () => {
    const options = getDefaultCacheCardOptions();
    const sslCert = WebsiteHelper.getCdnCacheSsl(website);
    const cdnDist = WebsiteHelper.getCdnCache(website);

    // Do not refresh card settings
    if (creating && !cdnDist && !sslCert) {
      window.logHelper.info('Initialize CDN cache card is locked: being created.', website);
      return false;
    }
    if (deleting && (cdnDist || sslCert)) {
      window.logHelper.info('Initialize CDN cache card is locked: being deleted.', website);
      return false;
    }

    // If no SSL then deployment hasn't started
    if (isEmptyOrNull(sslCert)) {
      setCacheCardOptions(options);
      return false;
    }
    setCacheCdnHost(sslCert.domain.replace(`.${registrableDomain}`, ''));

    // Set default as status "OK"
    options.type = 'status';
    options.statusColor = 'success';
    options.statusTooltip = 'Available';
    options.name = 'cdn-settings';
    options.btnText = 'Settings';
    options.secondBtn = 'Delete';
    options.spinnerWait = false;

    // If CDN does not exist then determine status based on SSL
    if (isEmptyOrNull(cdnDist)) {
      if (sslCert.status === 'PENDING_VALIDATION') {
        // It is being validated in the background
        options.statusColor = 'info';
        options.statusTooltip = 'Pending user action';
        options.spinner = false;
      } else if (sslCert.status !== 'ISSUED') {
        // If not issued then it has failed
        options.statusTooltip = 'SSL validation failed.';
        options.statusColor = 'danger';
        options.btnText = 'Revoke';
      } else if (cdnDeploymentError) {
        // Deployment failed
        options.statusTooltip = `Deployment failed: ${StringHelper.trim(cdnDeploymentError.error, '.')}. ${StringHelper.trim(cdnDeploymentError.solution, '.')}.`;
        options.statusColor = 'danger';
        options.btnText = false;
        options.secondBtn = 'Delete'; // not real delete but revokes SSL
      } else {
        // If issued but CDN deployment hasn't started
        options.spinnerText = 'Configuring CDN...';
        options.spinner = true;
        options.spinnerWait = true;
      }
      setCacheCardOptions(options);
      return true;
    }

    // At this point we have issued SSL + CDN
    options.name = 'cdn-settings';
    options.btnText = 'Settings';

    if (cdnDeploymentError) {
      // Check if any deployment error has occurred (but only if a
      // cdn exists, otherwise just ignore it)
      options.statusColor = 'danger';
      options.statusTooltip = cdnDeploymentError.solution || 'Please contact support.';
    } else if (sslCert.status !== 'ISSUED') {
      // SSL certificate not issued
      options.statusColor = 'danger';
      if (sslCert.status === 'FAILED') {
        options.statusTooltip = 'Revoke SSL certificate and start the process again.';
      } else if (sslCert.status === 'PENDING_VALIDATION') {
        options.statusTooltip =
          `The AWS Certificate Manager attempts to validate that you own or control the ${sslCert.domain} domain. ` +
          'If you have already added the DNS validation records, then please wait as it can take up to a few hours for ACM to obtain the new certificate. ' +
          'Otherwise, please click the Settings button and follow the instructions.';
      }
    } else {
      // Otherwise, determine status based on CDN
      if (cdnDist.status === 'impaired') {
        options.statusColor = 'danger';
        options.statusTooltip = cdnDist.status_reason;
      } else if (cdnDist.domain_status !== 'ok') {
        // If it were managed then the domain status is supposed to be ok that's why
        // we assume here that it is not managed
        options.statusColor = 'info';
        options.statusTooltip = 'Pending user action';
        options.spinner = false;
      } else if (cdnDist.status === 'initializing') {
        options.spinnerText = 'Deploying CDN...';
        options.spinner = true;
        options.spinnerWait = true;
      } else if (cdnDist.status === 'ok-has-warning') {
        options.statusTooltip = 'Available (action required)';
        options.statusColor = 'warning';
      } else if (cdnDist.status !== 'ok') {
        options.statusColor = 'danger';
        options.statusTooltip = cdnDist.status_reason || 'CDN deployment failed.';
      } else {
        // The default is ok
      }
    }

    setCacheCardOptions(options);
    return true;
  }

  const initCloudflare = () => {
    const zone = WebsiteHelper.getCloudflareZone(website);
    const options = getDefaultCloudflareCardOptions();
    setCloudflareCardOptions(options);
    if (!zone) {
      return false;
    }
    
    options.type = 'status';
    options.name = 'cloudflare-details';
    options.btnText = 'Details';
    options.secondBtn = 'Disconnect';
    options.spinnerWait = false;

    if (zone.status === 'pending') {
      options.statusColor = 'info';
      options.statusTooltip = 'Pending user action';
    } else if (zone.status === 'failed') {
      options.statusColor = 'danger';
      options.statusTooltip = 'Failed to connect Cloudflare.';
    } else if (zone.status === 'active') {
      options.statusColor = 'success';
      options.statusTooltip = 'Active';
    }
    setCloudflareCardOptions(options);
    return true;
  }

  const goToCloudflareGlobalSettings = () => history.push({ pathname: '/settings/cloudflare' });
  const goToCloudflareDetails = () => history.push({ pathname: `/websites/${website.slug}/cloudflare-details` });
  const goBackToCdnSettings = () => history.push({ pathname: `/websites/${website.slug}/cdn/settings` });
  const goBackToDnsZoneSettings = () => history.push({ pathname: `/websites/${website.slug}/dns-zone`, search: `?origin=cdn-setup` });

  const handleCloseModal = () => {
    setModal(false);
    setCacheCdnHost('cdn');
  };

  // Next-Gen CDN + Traditional CDN

  const handleProxyDeployClick = (e) => {
    if (isWebsiteBusy(website)) {
      dispatch(setGlobalInfoMsg({ text: 'Website is busy. Please wait a few seconds then try again.' }));
      return;
    }
    // Validate the website has DNS zone assigned
    if (!website.dns_zone_slug) {
      DialogHelper.info(
        modalDialog,
        'Your website must be assigned to a DNS Zone to use the Next-Gen CDN.',
        '',
        { btnText: 'Assign DNS Zone' }
      ).then(() => goBackToDnsZoneSettings());
    } else {
      DialogHelper.confirmAction(
        confirm,
        'deploy',
        '',
        'Next-Gen CDN',
        <p>{env.getBrandShortName()} will fully automate the deployment process by modifying your DNS records to generate an AWS CloudFront CDN and an AWS SSL Certificate.</p>,
        true
      ).then(() => {
        setProxyCardOptions(prev => ({...prev, loading: true }));
        setCacheCardOptions(prev => ({...prev, btnDisabled: true }));
        setCloudflareCardOptions(prev => ({...prev, btnDisabled: true }));
        // Deploy proxy cdn (automated process)
        const data = {
          website_slug: website.slug,
          domain: website.default_domain,
          alt_domains: website.domains,
          used_by: 'cdn_proxy',
          validation_method: 'DNS',
        };
        setCreating(true);
  
        // If the certificate is not deployed, then call the create ACM certificate API that will trigger
        // creating the SSL, and once it is issued, it will automatically try to deploy the CDN.
        // Otherwise, if the SSL is ready, try to deploy the CDN (in case of "Redeploy" button).
        const sslCert = WebsiteHelper.getCdnProxySsl(website);
        dispatch(sslCert && sslCert.status === 'ISSUED' ? deployWebsiteCdnProxy(data) : issueWebsiteAcmCertificate(data))
          .then(() => {
            dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'Website CDN', action: 'requested' }));
          }).catch((error) => {
            setCacheCardOptions(prev => ({...prev, btnDisabled: false }));
            initProxyCdn();
          }).finally(() => setCreating(false));
      })
    }
  }

  const handleProxyDeleteClick = () => {  
    if (isWebsiteBusy(website)) {
      dispatch(setGlobalInfoMsg({ text: 'Website is busy. Please wait a few seconds then try again.' }));
      return;
    }
    DialogHelper
      .confirmDelete(confirm, WebsiteHelper.getCdnProxySsl(website).domain, 'CDN')
      .then(() => {
        const data = {
          website_slug: website.slug,
        };
        setProxyCardOptions(prev => ({ ...prev, spinner: true, secondBtn: false, btnText: false, spinnerText: 'Deleting CDN...' }));
        setDeleting(true);
        dispatch(deleteWebsiteCdnProxy(data))
          .then(() => dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'CDN', action: 'deleted' })))
          .finally(() => setDeleting(false))
      });
  }

  const createCDN = () => {
    if (isWebsiteBusy(website)) {
      dispatch(setGlobalInfoMsg({ text: 'Website is busy. Please wait a few seconds then try again.' }));
      return;
    }
    if (cacheCdnHost.length < 1) {
      dispatch(setGlobalInfoMsg({ text: 'Domain cannot be empty.' }));
      return;
    }
    setLoading(true);
    setProxyCardOptions(prev => ({...prev, btnDisabled: true }));
    setCloudflareCardOptions(prev => ({...prev, btnDisabled: true }));
    const data = {
      website_slug: website.slug,
      domain: `${cacheCdnHost}.${registrableDomain}`,
      used_by: 'cdn_cache',
      validation_method: 'DNS',
    };
    setCreating(true);
    dispatch(issueWebsiteAcmCertificate(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'Website CDN', action: 'created' }));
        setModal(false);
        setLoading(false);
        goBackToCdnSettings();
      })
      .catch(() => {
        setLoading(false);
        setCreating(false);
      });
  };

  const handleCacheDeleteClick = () => {
    if (isWebsiteBusy(website)) {
      return;
    }
    DialogHelper
        .confirmDelete(confirm, WebsiteHelper.getCdnCacheSsl(website).domain, 'CDN')
        .then(() => {
          const data = { website_slug: website.slug };
          setCacheCardOptions(prev => ({ ...prev, spinner: true, secondBtn: false, btnText: false, spinnerText: 'Deleting CDN...' }));
          setDeleting(true);
          dispatch(deleteWebsiteCdnCache(data))
            .then(() => dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'CDN', action: 'deleted' })))
            .finally(() => setDeleting(false))
        })
  };

  // Cloudflare

  const connectCloudflare = () => {
    setLoading(true);
    dispatch(connectWebsiteCloudflareZone({ website_slug: website.slug, new_zone_id: modal.zone_id, account_id: modal.account_id }))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'connected' }));
        setModal(false);
        goToCloudflareDetails();
      })
      .catch((error) => DialogHelper.error(modalDialog, getErrorMsg(error)))
      .finally(() => setLoading(false));
  }

  const createNewCloudflareZone = () => {
    setLoading(true);
    const data = { website_slug: website.slug, name: website.registrable_domain, account_id: modal.account_id};
    dispatch(createWebsiteCloudflareZone(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'created' }));
        setModal(false);
        goToCloudflareDetails();
      })
      .catch((error) => DialogHelper.error(modalDialog, getErrorMsg(error)))
      .finally(() => setLoading(false));
  }

  const handleCloudflareDelete = () => {
    setLoading(true);
    const zone = WebsiteHelper.getCloudflareZone(website);
    const data = { website_slug: website.slug, disconnect_only: !modal.delete_zone, zone_id: zone.zone_id };
    dispatch(deleteWebsiteCloudflareZone(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'disconnected' }));
        setModal(false);
      })
      .catch((error) => DialogHelper.error(modalDialog, getErrorMsg(error)))
      .finally(() => setLoading(false));
  }
  
  const handleCloudflareConnectOrCreate = () => {
    if (isWebsiteBusy(website)) {
      dispatch(setGlobalInfoMsg({ text: 'Website is busy. Please wait a few seconds then try again.' }));
    } else if (modal.assign_existing_zone && !modal.zone_id) {
      dispatch(setGlobalInfoMsg({ text: `Please provider a ${CloudflareHelper.LABEL_WEBSITE_SERVICE}.` }));
    } else if (modal.assign_existing_zone && modal.zone_id) {
      connectCloudflare();
    } else {
      createNewCloudflareZone();
    }
  };

  const setAssignableCloudflareZones = (accountId) => {
    setModal(prev => ({ ...prev, assignable_zones: null }));
    const data = { account_id: accountId, website_slug: website.slug };
    CloudflareService.listAccountAssignableZones(data).then((zones) => {
      setModal(prev => ({ ...prev, assignable_zones: zones }));
    });
  }

  const openCloudflareConnectPopup = (accounts) => {
    accounts = accounts || cloudflareAccounts;
    setCloudflareCardOptions(prev => ({ ...prev, loading: false }));
    // If no accounts found, then show a message to connect Cloudflare.
    if (accounts.length === 0) {
      DialogHelper.popup(
        modalDialog,
        'Connect Cloudflare',
        goToCloudflareGlobalSettings,
        '<b>Cloudflare Account Missing</b>',
        'You need to connect your Cloudflare account to use this feature. Click the button below to connect your Cloudflare account.',
        {closeBtn: false, modalCloseIcon: true}
      );
      return;
    }
    // Open the Cloudflare connect popup.
    setModal({
      name: 'cloudflare-connect',
      accounts: accounts,
      account_id: accounts[0].account_id,
      assignable_zones: null, // loading
      assign_existing_zone: true,
      zone_id: '',
    });
    setAssignableCloudflareZones(accounts[0].account_id);
  }

  const handleCloudflareConnectClick = e => {
    if (!e) return;
    if (e.includes('cloudflare-details')) {
      return goToCloudflareDetails();
    }
    if (!cloudflareAccounts) {
      setCloudflareCardOptions(prev => ({ ...prev, loading: true }));
      CloudflareService.listAccounts({ partner_slug: website.partner_slug }).then((accounts) => {
        setCloudflareAccounts(accounts);
        openCloudflareConnectPopup(accounts);
      })
      .catch(error => DialogHelper.error(modalDialog, getErrorMsg(error)))
      .finally(() => setCloudflareCardOptions(prev => ({ ...prev, loading: false })));
    } else {
      openCloudflareConnectPopup();
    }
  };

  const handleCloudflareDeleteClick = () =>
    setModal({ name: 'cloudflare-delete', delete_zone: false });

  const handleCacheGetStartedClick = e => {
    if (!e) return;
    return e.includes('cdn-settings') ? goBackToCdnSettings() : setModal({ name: 'cache-get-started' });
  };

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Content Delivery Network</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
        {env.getBrandShortName()} offers multiple CDN solutions to speed up your website and protect it from DDoS attacks. Choose the one that best fits your needs.
      </p>
      <Content>
        {!displayCards && (
          <Fragment>
            <div style={{ marginTop: '10px' }}>
              The CDN service is only available for live websites.
            </div>
            <Link to='go-live'>
              <WPSButton type='button' className='update--btn' style={{margin: '24px 0'}}>
                Go live now
              </WPSButton>
            </Link>
          </Fragment>
        )}
        {displayCards && [proxyCardOptions, cacheCardOptions, cloudflareCardOptions].map(JsxHelper.createAdvancedTab)}
        {modal && modal.name === 'cache-get-started' && DialogHelper.inputs({
          title: 'Create CDN',
          icon: 'create',
          iconColor: 'success',
          confirmBtn: 'Create',
          onConfirm: createCDN,
          onClose: handleCloseModal,
          loading: loading,
          inputs: [
            {
              label: 'Enter CDN domain',
              placeholder: 'cdn',
              value: cacheCdnHost,
              required: true,
              onChange: e => setCacheCdnHost(e.target.value),
              suffix: `.${registrableDomain}`,
            },
          ],
        })}
        {modal && modal.name === 'cloudflare-connect' && DialogHelper.inputs({
          title: 'Connect Cloudflare',
          icon: 'cloudflare',
          iconColor: 'primary',
          confirmBtn: 'Connect',
          onConfirm: handleCloudflareConnectOrCreate,
          onClose: handleCloseModal,
          loading: loading,
          disabled: modal.assign_existing_zone && !modal.zone_id,
          header: modal.assign_existing_zone && !isNull(modal.assignable_zones) && isEmpty(modal.assignable_zones) ?
            JsxHelper.createWarningBox(`No assignable ${CloudflareHelper.LABEL_WEBSITE_SERVICE}s found. Please create a new one.`, true)
            : null,
          inputs: [
            {
              label: 'Account',
              name: 'account_id',
              type: 'select',
              value: modal.account_id,
              options: ArrayHelper.buildSelectOptions(modal.accounts, 'name', 'account_id'),
              onChange: e => {
                setModal({ ...modal, account_id: e.target.value })
                setAssignableCloudflareZones(e.target.value);
              }
            },
            modal.assign_existing_zone ? {
              label: CloudflareHelper.LABEL_WEBSITE_SERVICE,
              name: 'zone_id',
              type: 'select',
              placeholder: isNull(modal.assignable_zones) ? 'Loading...' : null,
              disabled: isNull(modal.assignable_zones),
              value: modal.zone_id,
              options: ArrayHelper.buildSelectOptions(modal.assignable_zones || [], 'name', 'zone_id'),
              onChange: e => setModal({ ...modal, zone_id: e.target.value }),
            } : {
              label: 'Domain',
              name: 'zone_name',
              value: website.registrable_domain,
              disabled: true,
              labelTooltip: CloudflareHelper.TOOLTIP_NEW_ZONE,
            },
            {
              label: `Create a new ${CloudflareHelper.LABEL_DNS_SERVICE}`,
              type: 'checkbox',
              checked: !modal.assign_existing_zone,
              onChange: e => setModal({ ...modal, assign_existing_zone: !e.target.checked }),
            },
          ],
        })}
        {modal && modal.name === 'cloudflare-delete' && DialogHelper.inputs({
          title: `Disconnect ${CloudflareHelper.LABEL_WEBSITE_SERVICE} `,
          icon: 'cloudflare',
          iconColor: 'warning',
          confirmBtn: 'Disconnect',
          btnColor: 'warning',
          onConfirm: handleCloudflareDelete,
          onClose: handleCloseModal,
          loading: loading,
          header: CloudflareHelper.getDeleteZoneModalHeader(),
        })}
      </Content>
    </Container>
  );
};

export default Cdn;
