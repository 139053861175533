import React, { Fragment, useState, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Can from 'utils/can';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch, useSelector } from 'react-redux';
import { usersSelector } from 'store/user/userSelectors';
import UserHelper from 'helpers/user';
import StringHelper from 'helpers/string';
import {
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
  setGlobalPleaseWaitMsg,
  setGlobalWarningMsg,
} from 'store/global/globalActions';
import useConfirm from 'hooks/useConfirm';
import UserService from 'services/user';
import { getAllUsers, deleteUser, changeUserEmail, setPartnerCustomWPAdminLoginUser, updateUserTwoFactorStatus, suspendPartnerAccount, unsuspendPartnerAccount } from 'store/user/userActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import TableHelper from 'helpers/table';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import ArrayHelper from 'helpers/array';
import { useForm } from 'react-hook-form';
import {exportCsvToFile, isEmptyOrNull} from 'helpers';
import env from 'config/env';
import useModal from 'hooks/useModal';

const Users = () => {
  useTitle('Users');
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();
  const users = useSelector(usersSelector);
  const BREADCRUMBS = JsxHelper.createBreadcrumbs('Users');
  const [ loading, setLoading ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const modalDialog = useModal();

  const SUSPENTION_CATEGORIES = [
    { label: 'Payment Due', value: 'payment_due', defaultMessage: 'Your access has been restricted due to an outstanding payment. Please settle your dues to regain access.' },
  ];

  // --------------------------------------------------
  // API + Handlers
  // --------------------------------------------------

  // Fetch all users. 
  const fetchUsers = () => {
    setLoading(true);
    const data = {};
    if (UserHelper.isAdminOrAgent()) {
      data.roles = ['admin', 'partner', 'agent', 'employee'];
    }
    dispatch(getAllUsers(data)).finally(() => setLoading(false));
  }

  const changeAccountEmail = () => {
    setModal(prev => ({ ...prev, loading: true }));
    const data = { user_slug: modal.user_slug, new_email: modal.new_email };
    dispatch(setGlobalPleaseWaitMsg({ model: 'Email', action: 'updating', forId: modal.user_slug }));
    dispatch(changeUserEmail(data))
      .then(() => {
        setModal(false);
        dispatch(setGlobalSuccessMsg({ model: 'Email', action: 'updated', forId: modal.user_slug }));
      })
      .catch(err => {
        setModal(prev => ({ ...prev, loading: false }));
        dispatch(setGlobalErrorMsg(err));
      });
  }

  const updateWPUserLogin = () => {
    setModal(prev => ({ ...prev, loading: true }));
    const data = { partner_slug: modal.user_slug, user_login: modal.user_login };
    dispatch(setGlobalPleaseWaitMsg({ model: 'Login user', action: 'updating', forId: modal.user_email }));
    dispatch(setPartnerCustomWPAdminLoginUser(data))
      .then(() => {
        setModal(false);
        dispatch(setGlobalSuccessMsg({ model: 'Login user', action: 'updated', forId: modal.user_email }));
      })
      .catch(err => {
        setModal(prev => ({ ...prev, loading: false }));
        dispatch(setGlobalErrorMsg(err));
      });
  }

  const suspendUser = () => {
    setModal(prev => ({ ...prev, loading: true }));
    const data = { user_slug: modal.user_slug, suspension_category: modal.suspension_category, suspension_message: modal.suspension_message };
    dispatch(setGlobalPleaseWaitMsg({ model: 'User', action: 'updating', forId: modal.user_email }));
    dispatch(suspendPartnerAccount(data))
      .then(() => {
        setModal(false);
        dispatch(setGlobalSuccessMsg({ model: 'User', action: 'suspended', forId: modal.user_email }));
      })
      .catch(err => {
        setModal(prev => ({ ...prev, loading: false }));
        dispatch(setGlobalErrorMsg(err));
      });
  }

  const unsuspendUser = (user) => {
    const data = { user_slug: user.slug };
    dispatch(setGlobalPleaseWaitMsg({ model: 'User', action: 'updating', forId: user.email }));
    dispatch(unsuspendPartnerAccount(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'User', action: 'unsuspended', forId: user.email })))
      .catch(err => dispatch(setGlobalErrorMsg(err)));
  }

  const toggleTwoFactorStatus = (user) => {
    const data = { user_slug: user.slug, two_factor_enabled: !user.two_factor_enabled };
    dispatch(setGlobalPleaseWaitMsg({ model: 'Two-factor', action: 'suspended', forId: user.email }));
    dispatch(updateUserTwoFactorStatus(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'Two-factor', action: 'updated', forId: user.email })))
      .catch(err => dispatch(setGlobalErrorMsg(err)));
  }

  const exportCSVFile = () => {
    if (isEmptyOrNull(users)) {
      dispatch(setGlobalWarningMsg('You cannot export an empty table.'));
      return;
    }
    const _data = users.map(user => ({
      'Display Name': user.display_name,
      'First Name': user.first_name,
      'Last Name': user.last_name,
      'Email': user.email,
      'Role': user.role,
      'Last Login (UTC)': user.last_login || '',
      'Verification Status': user.verification_status,
    }));
    exportCsvToFile(_data, 'users');
  };

  // --------------------------------------------------
  // Table Methods
  // --------------------------------------------------

  const actions = [
    {
      value: 'Update',
      doHide: (item) => !UserHelper.hasPermissions(`user:update:${item.slug}`),
      onClick: user => {
        history.push({
          pathname: `users/${user.slug}/update`,
          state: user,
        });
      },
    },
    {
      value: 'Change WordPress Login',
      doHide: (item) => !UserHelper.isAdminOrAgent() || item.role !== 'partner',
      onClick: user => {
        setModal({
          name: 'user-login',
          user_email: user.email,
          user_slug: user.slug,
          user_login: user.custom_wp_admin_user,
          header: <span>The current WordPress login user for <b>{user.display_name || user.email}</b> is set to "<b>{user.custom_wp_admin_user || user.slug}</b>"{!user.custom_wp_admin_user ? ' (default)' : ''}.
            {JsxHelper.createWarningBox('This will change the default WordPress login user for this partner.', true)}
          </span>
        });
      },
    },
    {
      value: `Change ${env.getBrandShortName()} Email`,
      doHide: (item) => !UserHelper.isAdminOrAgent() || item.role === 'admin',
      onClick: user => {
        setModal({
          name: 'user-email',
          new_email: user.email,
          user_slug: user.slug,
          header: <span>The current email for <b>{user.display_name || user.email}</b> is set to "<b>{user.email}</b>".</span>
        });
      },
    },
    {
      value: 'Suspend Account',
      doHide: (item) => item.role !== 'partner' || !UserHelper.isAdminOrAgent() || UserHelper.getSuspensionDetails(item),
      onClick: user => {
        setModal({
          name: 'suspend',
          user_email: user.email,
          user_slug: user.slug,
          suspension_category: SUSPENTION_CATEGORIES[0].value,
          suspension_message: SUSPENTION_CATEGORIES[0].defaultMessage,
          header: <span>You are about to suspend the account of <b>{user.display_name || user.email}</b>.
            {JsxHelper.createWarningBox('This will restrict access to the platform and services apart from the billing section.', true)}
          </span>
        });
      },
    },
    {
      value: 'Unsuspend Account',
      doHide: (item) => item.role !== 'partner' || !UserHelper.isAdminOrAgent() || !UserHelper.getSuspensionDetails(item),
      onClick: unsuspendUser,
    },
    {
      value: 'Enable 2FA',
      onClick: user => toggleTwoFactorStatus(user),
      doHide: user =>   !UserHelper.hasPermissions(`user:update:${user.slug}`) ||
                        user.two_factor_enabled ||
                        (user.role === 'admin' && !UserHelper.isAdmin())
    },
    {
      value: 'Disable 2FA',
      onClick: user => toggleTwoFactorStatus(user),
      doHide: user =>   !UserHelper.hasPermissions(`user:update:${user.slug}`) ||
                        !user.two_factor_enabled ||
                        (user.role === 'admin' && !UserHelper.isAdmin())
    },
    {
      value: 'Activity',
      onClick: user => {
        history.push({
          pathname: `users/${user.slug}/activity`,
          state: user,
        });
      },
    },
    {
      value: 'Employees',
      doHide: user => !UserHelper.isAdminOrAgent() || user.role !== 'partner',
      onClick: user => {
        history.push({
          pathname: `users/${user.slug}/children`,
          state: user,
          search: '?role=employee'
        });
      },
    },
    {
      value: 'Resend Verification Email',
      doHide: user => user.verification_status === 'verified',
      onClick: user => {
        const data = { user_slug: user.slug };
        UserService.resendVerificationEmail(data)
          .then(() => dispatch(setGlobalSuccessMsg({ model: 'Verification email', action: 'sent', to: user.email })),)
          .catch(err => dispatch(setGlobalErrorMsg(err)));
      },
    },
    {
      value: 'Send Password Reset Email',
      onClick: user => {
        const data = { user_slug: user.slug };
        UserService.sendConfirmIdentityEmail(data)
          .then(() =>
            dispatch(
              setGlobalSuccessMsg({
                model: 'Password reset email',
                action: 'sent',
                to: user.email,
              }),
            ),
          )
          .catch(err => dispatch(setGlobalErrorMsg(err)));
      },
    },
    {
      value: 'Send Confirm Identity Email',
      doHide: () => !UserHelper.isAdminOrAgent(),
      onClick: user => {
        const data = { user_slug: user.slug };
        UserService.sendConfirmIdentityEmail(data)
          .then((res) => DialogHelper.info(
            modalDialog,
            `A verification code has been sent to <b>${user.email}</b>.`,
            `Verification code: <b>${res.code}</b>`
          ))
          .catch(err => dispatch(setGlobalErrorMsg(err)));
      },
    },
    {
      value: 'Subscriptions',
      doHide: item => !UserHelper.isAdminOrAgent() || item.role !== 'partner',
      onClick: user => {
        history.push({
          pathname: `/billing/subscriptions`,
          search: `&filter=Subscriber:${user.display_name}`,
        });
      },
    },
    {
      value: 'Delete',
      doHide: (item) => !UserHelper.hasPermissions(`user:delete:${item.slug}`),
      onClick: user => DialogHelper
        .confirmDelete(confirm, user.email, 'user')
        .then(() => {
          dispatch(setGlobalPleaseWaitMsg({ id: user.email, model: 'user', action: 'deleted' }));
          dispatch(deleteUser(user))
            .then(() => dispatch(setGlobalSuccessMsg({ id: user.email, model: 'user', action: 'deleted' })))
            .catch(err => dispatch(setGlobalErrorMsg(err)))
        })
    },
  ];

  const headers = [
    {
      name: 'Name',
      selector: 'display_name',
      sortable: true,
      searchable: true,
      width: '30%',
      cell: (row) => {
        TableHelper.customizeCellValue(row, 'display_name', row.display_name + ' ' + row.email);
        return JsxHelper.createTableMultiLineCell({
          header: row.display_name,
          subheader: row.email,
        })
      }
    },
    UserHelper.isAdminOrAgent() && {
      name: 'Role',
      selector: 'role',
      width: '12%',
      sortable: true,
      searchable: true,
      cell: row => JsxHelper.createBubble({
        text: StringHelper.slugToText(row.role || ''),
        background: UserHelper.getRoleColor(row.role, row),
        customClass: 'margin-0',
        small: true,
      }),
    },
    UserHelper.isPartner() && {
      name: 'Role',
      selector: 'sub_role',
      sortable: true,
      searchable: true,
      width: '12%',
      cell: row => JsxHelper.createBubble({
        text: StringHelper.slugToText(row.sub_role || ''),
        background: UserHelper.getRoleColor(row.sub_role, row),
        customClass: 'margin-0',
        small: true,
      }),
    },
    {
      name: 'Status',
      selector: 'verification_status',
      sortable: true,
      searchable: true,
      width: '12%',
      cell: row => {
        const suspension = UserHelper.getSuspensionDetails(row) ? row.suspension_details : null;
        if (suspension) {
          return JsxHelper.createBubble({
            text: 'Suspended',
            sentence: true,
            background: 'danger',
            customClass: 'margin-0',
            tooltip: suspension.message,
            small: true,
          });
        }
        return JsxHelper.createBubble({
          text: row.verification_status,
          sentence: true,
          background: row.verification_status === 'verified' ? 'success' : 'warning',
          customClass: 'margin-0',
          small: true,
        });
      },
    },
    UserHelper.isAdminOrAgent() && JsxHelper.createTableTimeHeader('last_login', null, '14%', 'Last Login', 'No login yet.'),
    {
      name: 'Two Factor',
      selector: 'two_factor_enabled',
      sortable: true,
      width: '12%',
      cell: row => JsxHelper.createBubble({
        text: row.two_factor_enabled ? 'Enabled' : 'Disabled',
        background: row.two_factor_enabled ? 'success' : 'warning',
        customClass: 'margin-0',
        small: true,
      }),
    },
    JsxHelper.createTableActionsHeader(actions, UserHelper.isAdminOrAgent() ? '20%' : '34%'),
  ].filter(Boolean);

  // --------------------------------------------------
  // RENDER
  // --------------------------------------------------

  // Note: This is a workaround to prevent the table from re-rendering when the modal is being edited.
  const renderTable = useMemo(() => {
    return (
      <WPSDataTable columns={headers} body={users} loading={loading} />
    );
    // eslint-disable-next-line
  }, [users, loading]);

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={BREADCRUMBS}>
          <div style={{ display: 'flex' }}>
            Users
          </div>
        </TitleBar.Title>
        <TitleBar.Actions>
          {UserHelper.isAdminOrAgent() && JsxHelper.createButton({
            label: 'Load All',
            onClick: fetchUsers,
            loading,
          })}
          {UserHelper.isAdminOrAgent() && JsxHelper.createButton({
            label: 'Export',
            onClick: exportCSVFile,
          })}
          <Can
            perform='user:create:*'
            yes={() => <Link to='/users/create'>{JsxHelper.createButton({ label: 'Add User' })}</Link>}
          />
        </TitleBar.Actions>
      </TitleBar>
      <Content className='main-users-table'>
        {renderTable}
      </Content>
      {modal && modal.name === 'suspend' && DialogHelper.inputs({
        title: 'Suspend User Account',
        icon: 'lock',
        iconColor: 'warning',
        btnText: 'Suspend',
        header: modal.header,
        onConfirm: suspendUser,
        onClose: () => setModal(false),
        loading: modal.loading,
        register,
        inputs: [
          {
            type: 'select',
            name: 'provider',
            label: 'Suspension Category',
            options: ArrayHelper.buildSelectOptions(SUSPENTION_CATEGORIES, 'label', 'value'),
            value: modal.suspension_category,
            disabled: true,
            required: true,
            onChange: e => setModal({ ...modal, suspension_category: e.target.value }),
          },
          {
            type: 'textarea',
            name: 'suspension_message',
            label: 'Suspension Message',
            value: modal.suspension_message,
            onChange: e => setModal({ ...modal, suspension_message: e.target.value }),
            required: true,
          }
        ]
      })}
      {modal && modal.name === 'user-email' && DialogHelper.inputs({
        title: `Change ${env.getBrandShortName()} Account Email`,
        icon: 'edit',
        iconColor: 'success',
        btnText: 'Save',
        header: modal.header,
        onConfirm: handleSubmit(changeAccountEmail),
        onClose: () => setModal(false),
        loading: modal.loading,
        register,
        inputs: [{
          name: 'new_email',
          label: 'Email',
          placeholder: `Enter new email for ${modal.user_slug}`,
          value: modal.new_email,
          onChange: (e) => setModal({ ...modal, new_email: e.target.value }),
          errors,
          required: true,
        }]
      })}
      {modal && modal.name === 'user-login' && DialogHelper.inputs({
        title: 'Set Default WP Login',
        icon: 'edit',
        iconColor: 'success',
        btnText: 'Edit',
        header: modal.header,
        onConfirm: handleSubmit(updateWPUserLogin),
        onClose: () => setModal(false),
        loading: modal.loading,
        register,
        inputs: [{
          name: 'user_login',
          label: 'Username',
          placeholder: `Enter new user login for ${modal.user_slug}`,
          value: modal.user_login,
          onChange: (e) => setModal({ ...modal, user_login: e.target.value }),
          errors,
          required: true,
          minLength: 5,
        }]
      })}
    </Fragment>
  );
};

export default Users;
