import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import AdvancedTabs from 'components/website/advanced/advancedTabs/advancedTabs';
import UserHelper from 'helpers/user';
import JsxHelper from 'helpers/jsx';
import useTitle from 'hooks/useTitle';

const ToolIndex = () => {
  useTitle('Tools');

  const history = useHistory();
  const breadcrumbs = JsxHelper.createBreadcrumbs('tools');

  const cards = [
    {
      title: 'Bulk Reset Website Credentials',
      name: 'bulk-reset',
      desc: 'Allows a bulk reset of credentials across multiple website.',
      icon: 'restore',
      btnText: 'Actions',
      hide: UserHelper.isEmployee() || UserHelper.isAdminOrAgent(),
    },
    {
      title: 'Bulk Purge Website Cache',
      name: 'bulk-purge',
      desc: 'Clear cache for all sites in your Staq account that use the Staq Cache feature.',
      icon: 'trash',
      btnText: 'Actions',
      hide: UserHelper.isEmployee() || UserHelper.isAdminOrAgent(),
    },
    {
      title: 'Search Website Plugins / Themes',
      name: 'search-website-packages',
      desc: 'Searches through all the websites for a specific plugin or theme.',
      icon: 'databaseSearch',
      btnText: 'Search'
    },
    {
      title: 'Shell Scripts',
      name: 'run-shell-script',
      desc: 'Run a shell script on a website or multiple websites.',
      icon: 'terminal',
      btnText: 'Actions',
      hide: !UserHelper.isAdmin(),
    },
    {
      title: 'Activity Logs',
      name: 'activity-logs',
      desc: 'View activity logs for various microservices.',
      icon: 'activity',
      btnText: 'View',
      hide: !UserHelper.isAdmin(),
    },
    {
      title: 'Bulk Plugin / Theme Updates',
      name: 'bulk-update',
      desc: `Update multiple plugins / themes across all websites.`,
      icon: 'multipleCheck',
      btnText: 'Actions',
      hide: !UserHelper.isPartner(),
    },
  ];

  const handleOnClick = e => {
    if (e) {
      history.push({
        pathname: `/tools/${e}`,
      });
    }
  };

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Tools</TitleBar.Title>
      </TitleBar>
      <Content className='customers-selections'>
        {cards.filter(el => !el.hide).map((el, index) => {
          return (
            <AdvancedTabs
              key={index}
              type={el.type ? el.type : 'table'}
              name={el.name}
              desc={el.desc}
              icon={el.icon}
              title={el.title}
              btnText={el.btnText}
              onClick={e => handleOnClick(e)}
            />
          );
        })}
      </Content>
    </Fragment>
  );
};

export default ToolIndex;
