import React, { useState, Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { WPSForm } from 'styles/layout/forms';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy } from 'lodash';
import { globalPluginsListSelector, globalThemesSelector, globalCoresSelector } from 'store/globalPackage/globalPackageSelector';
import { partnerPluginsListSelector, partnerThemesSelector } from 'store/partnerPackage/partnerPackageSelector';
import { customersSelector } from 'store/customer/customerSelectors';
import { websitesSelector } from 'store/website/websiteSelectors';
import { activeSubscriptionsByType, activeSubscriptions, plansByCategoryType } from 'store/billing/billingSelectors';
import { customerWebsitePlansSelector, nonAssignedCustomerWebsitePlans } from 'store/customerBilling/customerBillingSelectors';
import { useHistory } from 'react-router-dom';
import { isEmpty, uniqBy, isEmptyOrNull } from 'helpers';
import UserHelper from 'helpers/user';
import ArrayHelper from 'helpers/array';
import WebsiteHelper from 'helpers/website';
import PlanHelper from 'helpers/plan';
import { useRef } from 'react';
import useTitle from 'hooks/useTitle';
import { partnersSelector } from 'store/user/userSelectors';
import { integrationSelector, partnerSelector } from 'store/me/meSelectors';
import JsxHelper from 'helpers/jsx';
import { sharedServersSelector } from 'store/server/serverSelectors';
import useModal from 'hooks/useModal';
import WebsiteOptionsTrait from './WebsiteOptionsTrait';
import 'components/stepper/stepper.css';

const WebsiteCreate = () => {
  useTitle('Website Create');
  const dispatch = useDispatch();
  const history = useHistory();
  const modalDialog = useModal();
  const STEPS_NUM = 3;
  const stepperCreateSiteRef = useRef();
  const { register, handleSubmit, errors, triggerValidation } = useForm({ reValidateMode: 'onSubmit' });
  const [modal, setModal] = useState(false);
  const payAsYouGoServers = useSelector(sharedServersSelector);

  // Check stripe status to hide the client step if it's not Connected
  const integrations = useSelector(integrationSelector);
  const isStripeConnected = integrations.stripe ? true : false;

  // If two or more option keys are the same then the priority order is maintained
  // (private > partner > global) but the appearance order is reversed (global first).
  const globalPluginSelectOptions = useSelector(globalPluginsListSelector);
  const partnerPluginSelectOptions = useSelector(partnerPluginsListSelector);
  const pluginSelectOptions = ArrayHelper.reverse(
    uniqBy([...partnerPluginSelectOptions, ...globalPluginSelectOptions], 'key'),
  );
  // If two or more folder_names are the same then the priority order is maintained
  // (private > partner > global) but the appearance order is reversed (global first).
  const globalThemes = useSelector(globalThemesSelector);
  const partnerThemes = useSelector(partnerThemesSelector);
  const globalCores = sortBy(useSelector(globalCoresSelector), p => p.created_at).reverse();
  const partner = useSelector(partnerSelector);
  const partnerWebsitePlans = useSelector(plansByCategoryType('website'));
  const customers = useSelector(customersSelector);
  const customerPlans = useSelector(customerWebsitePlansSelector);
  const nonAssignedPlans = useSelector(nonAssignedCustomerWebsitePlans);
  const websiteActiveSubscriptions = useSelector(activeSubscriptionsByType('website'));
  const partnersSubscriptions = useSelector(activeSubscriptions());
  const [partnerPlans, setPartnerPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(false);
  const partners = useSelector(partnersSelector);
  const allWebsites = useSelector(websitesSelector);
  const defaultWebsiteData = WebsiteOptionsTrait.initWebsiteData(
    partner,
    customerPlans,
    partnerThemes,
    globalThemes,
    globalCores
  );

  // Admin: select the available partner plans when a partner is selected
  const initSelectedPartnerInputs = partnerSlug => {
    // Find partner
    const partner = partners.find(p => p.slug === partnerSlug);
    if (isEmptyOrNull(partner)) {
      setPartnerPlans([]);
      return;
    }
    // Get all partner subscriptions
    const partnerSubscriptions = partnersSubscriptions ? partnersSubscriptions.filter(
      s => s.subscriber_slug === partnerSlug,
    ) : [];
    // Filter subscribable plans
    const _partnerPlans = PlanHelper.filterSubscribableWebsitePlans(partnerWebsitePlans, partnerSubscriptions, partnerSlug);
    setPartnerPlans(_partnerPlans);
    // Set website partner
    setWebsite(prev => ({
      ...prev,
      wp_admin_user: WebsiteHelper.getCustomWPAdminUser(partner),
      wp_admin_user_email: partner.email,
    }));
  };

  // Fetch all partners subscriptions on component mount.
  useEffect(() => {
    if (UserHelper.isAdminOrAgent()) {
      WebsiteOptionsTrait.fetchPartnerSubscriptionsForAdmins(partnersSubscriptions, dispatch);
    } else {
      const _partnerPlans = PlanHelper.filterSubscribableWebsitePlans(partnerWebsitePlans, websiteActiveSubscriptions);
      setPartnerPlans(_partnerPlans);
    }
    // eslint-disable-next-line
  }, []);

  const doHideCustomerStep = () => UserHelper.isAdminOrAgent() || !isStripeConnected

  const initStep = stepNum => {
    const stepsCount = STEPS_NUM - (doHideCustomerStep() ? 1 : 0);
    WebsiteOptionsTrait.initStep(stepperCreateSiteRef, refDisabledCreateSiteSteps, stepNum, stepsCount, setCreateSiteCurrentStepForData);
  };

  /* eslint-disable no-unused-vars */
  const [createSiteStepperSteps, setCreateSiteStepperSteps] = useState(
    [
      {
        title: 'Choose Plan',
        onClick: () => initStep(1),
      },
      !doHideCustomerStep() && {
        title: 'Assign Client',
        onClick: () => initStep(2),
      },
      {
        title: 'WordPress Setup',
        onClick: () =>  initStep(doHideCustomerStep() ? 2 : 3),
      },
    ].filter(Boolean)
  )

  const [createSiteCurrentStep, setCreateSiteCurrentStep] = useState(1);
  const [createSiteCurrentStepForData, setCreateSiteCurrentStepForData] = useState(1);
  const CustomerSteps = doHideCustomerStep() ? [1] : [1, 2];
  const [disabledCreateSiteStepsData, setDisabledCreateSiteStepsData] = useState([
    ...CustomerSteps,
  ]);
  const refDisabledCreateSiteSteps = useRef([...CustomerSteps]);
  const [website, setWebsite] = useState(defaultWebsiteData);

  const [customerPlansList, setCustomerPlansList] = useState();

  // Website migration states.
  const [websiteExist, setWebsiteExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [websiteExistError, setWebsiteExistError] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(WebsiteOptionsTrait.EMPTY_CUSTOMER_DETAILS);
  const [customerLoading, setLoadingCustomer] = useState(false);
  const [customersTrigger, setCustomersTrigger] = useState(1);

  // Set the free trial notice for partner whenever a new subscription is set
  useEffect(() => {
    WebsiteOptionsTrait.onPartnerPlanSelection(
      website.partner_plan_slug,
      websiteActiveSubscriptions,
      partnerPlans,
      setSelectedPlan,
      setWebsite
    );
    // eslint-disable-next-line
  }, [website.partner_plan_slug, partnerPlans]);

  useEffect(() => {
    if (!UserHelper.isAdminOrAgent()) {
      if (website.customer_slug) {
        // Selected customer plans
        const selectedCustomerPlans = customerPlans.filter(
          plan => plan.assignee_slug === website.customer_slug,
        );
        const allPlans = [...nonAssignedPlans, ...selectedCustomerPlans];
        setCustomerPlansList(allPlans);
      } else {
        setCustomerPlansList(nonAssignedPlans);
      }
    }
  }, [customerPlans, nonAssignedPlans, website.customer_slug]);

  const handleOnChange = e => {
    const { name } = e.target;
    let value = '';
    if (name === 'partner_slug') {
      // If an admin changes partner then the selected partner's
      // subscriptions must be updated
      value = e.target.value;
      initSelectedPartnerInputs(value);
    } else if (name === 'region_slug') {
      value = e.target.id;
    } else if (name === 'website_slug') {
      value = e.target.value.toLowerCase();
    } else {
      value = e.target.value;
    }
    setWebsite(prevState => ({ ...prevState, [name]: value }));
  };

  const onDeploySite = () => WebsiteOptionsTrait.onCreate(websiteExist, website, history, dispatch, setLoading);

  const handleWebsiteIdOnBlur = () => {
    const onNotExists = () => {
      if (!isEmpty(refDisabledCreateSiteSteps.current)) {
        if (!doHideCustomerStep()) {
          changeCreateSiteSteps(2);
          refDisabledCreateSiteSteps.current = [2];
          setCreateSiteCurrentStepForData(2);
          setDisabledCreateSiteStepsData([2]);
        } else {
          changeCreateSiteSteps(2);
          refDisabledCreateSiteSteps.current = [];
          setDisabledCreateSiteStepsData([]);
          setCreateSiteCurrentStepForData(2);
        }
      }
      initStep(2);
    }
    WebsiteOptionsTrait.onWebsiteIdBlur(website, setLoading, loading, setWebsiteExistError, setWebsiteExist, onNotExists, modalDialog);
  };

  const handleCreateSiteThirdStep = () => {
    changeCreateSiteSteps(3);
    refDisabledCreateSiteSteps.current = [];
    setDisabledCreateSiteStepsData([]);
    setCreateSiteCurrentStepForData(3);
    initStep(3);
  };

  const changeCreateSiteSteps = newIndex => {
    setCreateSiteCurrentStep(newIndex);
    let data = disabledCreateSiteStepsData;
    data.push(createSiteCurrentStep - 1);
    let fitleredSteps = data.filter(el => el !== newIndex - 1);
    refDisabledCreateSiteSteps.current = fitleredSteps;
    setDisabledCreateSiteStepsData(fitleredSteps);
  };

  // Filter the relevant plugin/theme options. In case of Admin user, the
  // partner rofile is dynamically selected and therefore it should filter
  // out plugins/themes that belong to other partner accounts.
  const getPartnerPlugins = () => {
    let _partnerPlugins = pluginSelectOptions;
    // Filter out plugins that do not belong to the selected partner
    if (UserHelper.isAdminOrAgent()) {
      _partnerPlugins = pluginSelectOptions.filter(p => p.partner_slug === website.partner_slug);
    }
    // Add global plugins that do not exist also as partner plugins
    for (const i in globalPluginSelectOptions) {
      const index = _partnerPlugins.findIndex(
        p => p.key === globalPluginSelectOptions[i].key,
      );
      if (index === -1) {
        _partnerPlugins.push(globalPluginSelectOptions[i]);
      }
    }
    return _partnerPlugins;
  };
  const getPartnerThemes = () => {
    // Filter out themes that do not belong to the selected partner
    let _partnerThemes = partnerThemes;
    if (UserHelper.isAdminOrAgent()) {
      _partnerThemes = partnerThemes.filter(t => t.partner_slug === website.partner_slug);
    }
    // Add global themes that do not exist also as partner themes
    for (const i in globalThemes) {
      const index = _partnerThemes.findIndex(t => t.folder_name === globalThemes[i].folder_name);
      if (index === -1) {
        _partnerThemes.push(globalThemes[i]);
      }
    }
    // Include version in label
    const __partnerThemes = [..._partnerThemes].map(t => {
      return {
        slug: t.slug,
        display_name: `${t.display_name} (v${t.version})`,
      };
    });
    return ArrayHelper.buildSelectOptions(__partnerThemes, 'display_name', 'slug');
  };

  const breadcrumbs = JsxHelper.createBreadcrumbs('Create Website', 'website');

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Create Website</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content style={{ position: 'relative' }}>
        <div
          className='create-site-stepper'
          style={doHideCustomerStep() ? { left: '-85px' } : {}}
          ref={stepperCreateSiteRef}>
          {JsxHelper.createStepper(createSiteStepperSteps, createSiteCurrentStep, disabledCreateSiteStepsData)}
        </div>
        <WPSForm
          onSubmit={handleSubmit(onDeploySite)}
          noValidate
          style={{ maxWidth: '1000px', marginTop: '130px' }}>
          {createSiteCurrentStepForData === 1 && (
            <Fragment>
              {WebsiteOptionsTrait.renderRegionsAndZones(handleOnChange)}
              {!isEmptyOrNull(website.region_slug) && <Fragment>
                <WPSForm.Row className='display-block' style={{ marginLeft: '2px' }}>
                  {WebsiteOptionsTrait.renderEnterAppID(website, websiteExist, websiteExistError, handleOnChange)}
                  {WebsiteOptionsTrait.renderSelectPartnerForAdmins(website, partners, errors, register, handleOnChange)}
                  {WebsiteOptionsTrait.renderPricingPlansForPartner(website, partnerPlans, handleOnChange)}
                  {WebsiteOptionsTrait.renderPricingPlansForAdmins(website, partnerPlans, handleOnChange)}
                  {WebsiteOptionsTrait.renderServersForAdmins(website, payAsYouGoServers, selectedPlan, register, handleOnChange, allWebsites)}
                </WPSForm.Row>
                {WebsiteOptionsTrait.renderLightButton('Next', handleSubmit(handleWebsiteIdOnBlur), loading)}
              </Fragment>}
            </Fragment>
          )}
          {createSiteCurrentStepForData === 2 && !doHideCustomerStep() && (
            <Fragment>
              {!UserHelper.isAdminOrAgent() && (
                <Fragment>
                  {WebsiteOptionsTrait.renderAssignClient(website, customers, customersTrigger, handleOnChange, setModal)}
                  {website.customer_slug ? WebsiteOptionsTrait.renderAgencyPlans(website, customerPlansList, handleOnChange) : null}
                </Fragment>
              )}
              {WebsiteOptionsTrait.renderLightButton('Next', handleSubmit(handleCreateSiteThirdStep), loading)}
            </Fragment>
          )}
          {(createSiteCurrentStepForData === 3 || (createSiteCurrentStepForData === 2 && doHideCustomerStep())) && (
            <Fragment>
              <WPSForm.Fieldset
                className='display-block'
                style={{ marginTop: '0px', marginLeft: '2px' }}>
                <legend>Admin User</legend>
                {WebsiteOptionsTrait.renderTextInputRowItem('Admin User', 'wp_admin_user', website, errors, register, true, handleOnChange)}
                {WebsiteOptionsTrait.renderTextInputRowItem('Admin Email', 'wp_admin_user_email', website, errors, register, true, handleOnChange)}
              </WPSForm.Fieldset>
              <WPSForm.Fieldset className='display-block' style={{ marginLeft: '2px' }}>
                <legend>Settings</legend>
                {WebsiteOptionsTrait.renderSelectInputRowItem('PHP Version', 'php_version', website, ArrayHelper.buildSelectOptions(WebsiteHelper.phpVersions, 'name', 'value'), true, handleOnChange)}
                {WebsiteOptionsTrait.renderSelectInputRowItem('WordPress Version', 'wp_version', website, ArrayHelper.buildSelectOptions(globalCores, 'version', 'version'), true, handleOnChange)}
                {WebsiteOptionsTrait.renderSelectInputRowItem('Plugins', 'plugin_slugs', website, getPartnerPlugins(), false, e => setWebsite(prev => ({ ...prev, plugin_slugs: e.target.values })), true)}
                {WebsiteOptionsTrait.renderSelectInputRowItem('Theme', 'theme_slug', website, getPartnerThemes(), true, handleOnChange)}
                {WebsiteOptionsTrait.renderTextInputRowItem('Site Title', 'site_title', website, errors, register, true, handleOnChange)}
              </WPSForm.Fieldset>
              {WebsiteOptionsTrait.renderLightButton('Create Site', handleSubmit(onDeploySite), loading)}
            </Fragment>
          )}
        </WPSForm>
      </Content>
      {modal && WebsiteOptionsTrait.createNewClientModal(
        loading,
        customerLoading,
        customerDetails,
        errors,
        register,
        setModal,
        handleSubmit(() => WebsiteOptionsTrait.createCustomer(
          customerDetails,
          setModal,
          setCustomersTrigger,
          setLoadingCustomer,
          triggerValidation,
          setWebsite,
          dispatch
        )),
        setCustomerDetails
      )}
    </Fragment>
  );
};

export default WebsiteCreate;
