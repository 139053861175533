import { postRequest, deleteRequest, getRequest, downloadRequest } from '../config/axiosRequest';
import env from '../config/env';

// Gets the client report config for a partner.
const getPartnerConfig = data => getRequest({
  url: env.buildApiEndpoint(`client-reports/config`),
  data,
});

// Updates the client report config for a partner.
const updatePartnerConfig = data => postRequest({
  url: env.buildApiEndpoint(`client-reports/config`),
  data,
});
  
// List all client report PDFs.
const listPDFs = data => getRequest({
  url: env.buildApiEndpoint(`client-reports/pdfs`),
  data,
});

// List all client report receivers.
const listReceivers = data => getRequest({
  url: env.buildApiEndpoint(`client-reports/receivers`),
  data,
});
  
// Create a client report pdf.
const createPDF = data => postRequest({
  url: env.buildApiEndpoint(`client-reports/pdf`),
  data,
});

// Create a client report receiver.
const createReceiver = data => postRequest({
  url: env.buildApiEndpoint(`client-reports/receiver`),
  data,
});

// Updates an existing client report receiver.
const updateReceiver = data => postRequest({
  url: env.buildApiEndpoint(`client-reports/receiver/${data.guid}`),
  data,
});

// Retrieve a client report pdf.
const downloadPDF = data => downloadRequest({
  url: env.buildApiEndpoint(`client-reports/download-url`),
  data
});

// Sends a client report pdf.
const sendPDF = data => postRequest({
  url: env.buildApiEndpoint(`client-reports/send-pdf`),
  data
});

// Delete a client report pdf.
const deletePDF = data => deleteRequest({
  url: env.buildApiEndpoint(`client-reports/pdf`),
  data,
});
  
// Delete a client report receiver.
const deleteReceiver = data => deleteRequest({
  url: env.buildApiEndpoint(`client-reports/receiver`),
  data,
});
  
const ClientReportService = {
  getPartnerConfig,
  updatePartnerConfig,
  listPDFs,
  listReceivers,
  sendPDF,
  createPDF,
  createReceiver,
  updateReceiver,
  downloadPDF,
  deletePDF,
  deleteReceiver,
};
  
  export default ClientReportService;
  