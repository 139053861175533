import React, { useState, useEffect, Fragment, useRef} from 'react';
import { DomainStep } from 'styles/website/profile';
import { WPSForm } from 'styles/layout/forms';
import { useDispatch } from 'react-redux';
import {
  fetchWebsite,
  fetchWebsiteSsl,
  requestWebsiteSsl
} from 'store/website/websiteActions';
import { isEmptyOrNull, isWebsiteBusy } from 'helpers';
import WebsiteHelper from 'helpers/website';
import JsxHelper from 'helpers/jsx';
import UrlHelper from 'helpers/url';
import useModal from 'hooks/useModal';
import { useForm } from 'react-hook-form';
import DialogHelper from 'helpers/dialog';
import StringHelper from 'helpers/string';
import useConfirm from 'hooks/useConfirm';
import 'components/stepper/stepper.css';

const SslStep = ({ website, nextBtnHandler, noAutoConfig }) => {
  const dispatch = useDispatch();
  const modalDialog = useModal();
  const confirm = useConfirm();
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const [sslPrevStatus, setSslPrevStatus] = useState(null);
  const [sslDetails, setSslDetails] = useState(null);
  const [installInstructions, setInstallInstructions] = useState(null);
  const [showInstallFreeSslBtn, setShowInstallFreeSslBtn] = useState(true);
  const [isCustomSslInstalled, setIsCustomSslInstalled] = useState(false);
  const [installCustomSslModal, setInstallCustomSslModal] = useState(false);
  const [customSslLoading, setCustomSslLoading] = useState(false);
  const [revokeSslLoading, setRevokeSslLoading] = useState(false);
  const [freeSslLoading, setFreeSslLoading] = useState(false);
  const [sslCheckLoading, setSslCheckLoading] = useState(false);
  const [sslRenewBusy, setSslRenewBusy] = useState(false);
  const [sslStatusText, setSslStatusText] = useState(false);
  const [sslLoadingError, setSslLoadingError] = useState(false);
  const [sslErrors, setSslErrors] = useState(null);
  const [sslPageOpened, setSslPageOpened] = useState(false);
  const action = UrlHelper.getQueryParam('action');
  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    }
  }, []);

  useEffect(() => {
    const domainsSsl = website.domains_ssl;
    if (!action && domainsSsl && domainsSsl.status === 'valid') {
      handleSslResponse(website, domainsSsl, true);
    } else if (!sslPageOpened) {
      if (noAutoConfig && !installInstructions) {
        setInstallInstructions('Please configure your SSL certificate using the options below.');
      } else {
        if (isWebsiteBusy(website)) {
          onCreateSsl(website);
        } else if (!freeSslLoading && !sslRenewBusy && !sslDetails) {
          onCheckSsl(website);
        }
      }
      setSslPageOpened(true);
    }
    // eslint-disable-next-line
  }, [website]);

  const refreshSite = (retry) => {
    if (!mounted.current) {
      window.logHelper.warning('Ignored site refresh: component unmounted.');
      return;
    }
    const data = {
      website_slug: website.slug,
      check_status: true
    }
    dispatch(fetchWebsite(data))
    .then(_website => {
      if (_website.status !== 'ok' && retry) {
        window.logHelper.info('Status is still not ok. Refreshing site again...');
        setTimeout(() => refreshSite(false), 5 * 1000);
      }
    })
  }

  const onCreateSsl = (_website, isCustom) => {
    let data = {
      website_slug: website.slug,
    }
    if (isCustom) {
      data.certificate = installCustomSslModal.certificate;
      data.private_key = installCustomSslModal.private_key;
      data.ca_bundle = installCustomSslModal.ca_bundle;
      if (!data.certificate || !data.private_key) {
        DialogHelper.error('Please fill all certificate fields.');
        return;
      }
      if (!data.ca_bundle) {
        DialogHelper.confirmAction(
          confirm,
          'missing',
          '',
          'CA Bundle',
          'It is recommended to provide the CA Bundle for better compatibility and security.',
          true,
          'Continue'
        ).then(() => performCreateSsl(_website, data, isCustom));
        return;
      }
    }
    performCreateSsl(_website, data, isCustom);
  }

  const performCreateSsl = (_website, data, isCustom) => {
    setSslStatusText('SSL certificate is being generated...');
    setSslErrors(null);
    isCustom ? setCustomSslLoading(true) : setFreeSslLoading(true);
    isCustom && toggleInstallCustomSslModal(false);
    dispatch(requestWebsiteSsl(data))
    .then((ssl) =>  handleSslResponse(_website, ssl, false))
    .catch(() => {
      setSslRenewBusy(false);
      onCheckSsl(_website);
    })
    .finally(() =>
      isCustom ? setCustomSslLoading(false) : setFreeSslLoading(false)
    );
  }

  const onCheckSsl = (_website) => {
    if (!mounted.current) {
      window.logHelper.warning('Ignored SSL certificate check: component unmounted.');
      return;
    }
    window.logHelper.info('Checking SSL certificate...');
    if (freeSslLoading) {
      window.logHelper.warning('Ignored SSL certificate check: renew process in progress.');
      return;
    }
    setSslCheckLoading(true);
    setSslLoadingError(false);
    setSslErrors(null);
    setSslStatusText('Loading SSL certificate details...');
    const data = {
      website_slug: website.slug,
    };
    dispatch(fetchWebsiteSsl(data))
    .then((ssl) => handleSslResponse(_website, ssl, false))
    .catch((err) => {
      setSslRenewBusy(false);
      setSslLoadingError('Oops! Something went wrong. Please try again.');
      window.logHelper.warning('SSL certificate check failed:', err);
    })
    .finally(() => {
      setSslCheckLoading(false);
      setSslStatusText(false);
    });
  }

  const handleSslResponse = (_website, ssl, calledFromInit) => {
    // Parse details and prepare display parameters
    const details = WebsiteHelper.parseSSL(_website.domains, ssl);
    window.logHelper.info('SSL parsed details:', details);

    // Check edge case where the backend still hasn't saved the ssl details
    const sslProcess = details.ssl ? details.ssl.process : null;
    if (sslProcess) {
      window.logHelper.info('SSL process:', sslProcess);
      const sslExists = details.ssl.subject || details.ssl.certName;
      const isExpired = details.ssl.status === 'expired';
      if (sslExists && details.status === 'invalid' && sslProcess.status === 'succeeded' && !isExpired) {
        window.logHelper.warning('SSL is valid but details are not updated yet. Refreshing...');
        return onCheckSsl(_website);
      }
    }

    // Check background processes
    const isNewCertBusy = ssl.errorCode === 'same-process-busy'; // new being created
    const isOldCertBusy = details && details.status === 'busy'; // existing being extended
    if (isNewCertBusy || isOldCertBusy) {
      window.logHelper.info('Background SSL process detected. Waiting 25s to refresh...');
      setTimeout(() => onCheckSsl(_website), 25 * 1000);
      setSslRenewBusy(true);
      return;
    }

    // If it became valid just now then refresh site
    setSslPrevStatus(ssl.status);
    if (!calledFromInit && (!sslPrevStatus || sslPrevStatus !== 'valid') && ssl.status === 'valid') {
      window.logHelper.info('SSL status is valid. Refreshing site status...');
      setTimeout(() => refreshSite(true), 5 * 1000);
    }

    // If no background process then update display
    const showModal = !calledFromInit;
    updateSsl(details, _website, showModal);
  }

  const updateSsl = (details, _website, showModal) => {
    setSslStatusText(null);
    const ssl = details.ssl;
    const statusJsx = (
    <div className='ssl-status'>
      {JsxHelper.createBubble({
        background: details.modalParams.type,
        text: StringHelper.toText(details.status),
        tooltip: details.tooltip,
        small: true,
      })}
      {details.status === 'invalid' &&
        <span className='error-msg'>{details.tooltip}</span>
      }
    </div>)

    setSslDetails({
      details: details,
      display: {
        'Issuer': details.issuer,
        'Issued To': details.subject || false,
        'Domains': ssl && ssl.domains ? JsxHelper.createItemsList(ssl.domains) : null,
        'Status': statusJsx,
        'Expiry Date': details.validTo ? details.validTo.split(' ')[0] : '-',
        'Renewal': details.autoRenew ? 'Auto' : 'Manual',
      }
    });

    const processErrors = ssl.process && !isEmptyOrNull(ssl.process.errors) ? ssl.process.errors : {};
    const domainErrors = ssl.errors ? ssl.errors : {};
    let sslErrors = { ...domainErrors, ...processErrors };
    if (sslErrors) {
      for (const failedDomain in sslErrors) {
        if (ssl.domains && ssl.domains.find(d => UrlHelper.areEquals(failedDomain, d))) {
          delete sslErrors[failedDomain]; // Already has valid SSL
        } else if (!_website.domains.find(d => UrlHelper.areEquals(failedDomain, d))) {
          delete sslErrors[failedDomain]; // No longer a website domain
        }
      }

      setSslErrors(sslErrors);
    }

    // Determine whether to show install custom SSL button.
    setIsCustomSslInstalled(details.isCustom);

    // Only show free button if not custom and not valid or missing domains.
    setShowInstallFreeSslBtn(!details.isCustom && (details.status !== 'valid' || !isEmptyOrNull(details.missingDomains)));

    // Turn off busy flag.
    setSslRenewBusy(false);

    if (showModal) {
      if (details.modalParams.type === 'error') {
        DialogHelper.error(modalDialog, details.modalParams.line1, details.modalParams.line2, details.modalParams);
      } else if (details.modalParams.type === 'warning') {
        DialogHelper.warning(modalDialog, details.modalParams.line1, details.modalParams.line2, details.modalParams);
      }
    }
  }

  const onChangeCustomSslField = (e) => {
    const { name, value } = e.target;
    setInstallCustomSslModal({
      ...installCustomSslModal,
      [name]: value,
    });
  }

  const toggleInstallCustomSslModal = (mode) => {
    setInstallCustomSslModal(mode ? {
      certificate: '',
      private_key: '',
      ca_bundle: '',
    } : false);
  }

  const onRevokeCustomSsl = () => {
    DialogHelper.confirmAction(
      confirm,
      'revoke',
      '',
      'SSL certificate',
      'Your custom SSL certificate will be revoked and a free SSL certificate will be installed instead.',
    ).then(() => {
      const data = {
        website_slug: website.slug,
        revoke_cert: true,
      };
      setRevokeSslLoading(true);
      dispatch(requestWebsiteSsl(data))
      .then(() => {
        setRevokeSslLoading(false);
        onCheckSsl(website);
      })
      .catch(() => setRevokeSslLoading(false));
    })
  }

  return (<Fragment>
    <DomainStep className='ssl-step'>
      {!isEmptyOrNull(sslErrors) && (
        <WPSForm.Fieldset style={{ marginTop: '0px', marginBottom: '24px' }}>
          <legend>SSL Certificate Errors</legend>
          <WPSForm.RowItem className='website-id-container'>
            <div className='info-container'>
              {Object.keys(sslErrors).map(k => (
                <div className='info-row' key={k}>
                  <span className='info-label'>{k}</span>
                  <span className='danger-font-color info-line'>{StringHelper.capitalizeFirstLetter(sslErrors[k].reason)}</span>
                </div>
              ))}
            </div>
          </WPSForm.RowItem>
        </WPSForm.Fieldset>
      )}
      {(!installInstructions || !isEmptyOrNull(sslDetails)) ? <WPSForm.Fieldset style={{ marginTop: '0px' }}>
        <legend>SSL Certificate Details</legend>
        <WPSForm.RowItem className='website-id-container'>
          <div id='ssl-info' className='info-container'>
          {sslStatusText && !sslCheckLoading && !sslRenewBusy && !freeSslLoading && (
            <span>{sslStatusText}</span>
          )}
          {sslStatusText && (sslCheckLoading || sslRenewBusy || freeSslLoading) && (
            <div className='info-row'>{JsxHelper.createTableLoader(sslStatusText)}</div>)}
          {!sslStatusText && sslDetails && sslDetails.display && Object.keys(sslDetails.display).filter(k => !!sslDetails.display[k]).map(k => (
            <div className='info-row' key={k}>
              <span className='info-label'>{k}</span>
              <span className='info-line'>{sslDetails.display[k]}</span>
            </div>
          ))}
          {!sslStatusText && !sslDetails && (
            !sslLoadingError
              ? <div className='info-row'>{JsxHelper.createTableLoader()}</div>
              : <div className='info-row'><span className='danger-font-color'>{sslLoadingError}</span></div>
          )}
          </div>
        </WPSForm.RowItem>
      </WPSForm.Fieldset> : <Fragment>
        {JsxHelper.createTipBox(
          'Please configure your SSL certificate using the options below.',
          true,
          '',
          { margin: '24px 6px' }
        )}
      </Fragment>}
      <div className='action-buttons' style={{ display: 'flex', marginTop: '35px', marginLeft: '6px' }}>
        {JsxHelper.createButton({
          label: 'Refresh Status',
          loading: sslCheckLoading,
          disabled: freeSslLoading || sslRenewBusy || customSslLoading || revokeSslLoading,
          onClick: () => onCheckSsl(website)
        })}
        {showInstallFreeSslBtn ? JsxHelper.createButton({
          label: `${website && website.domains_ssl ? 'Renew' : 'Install'} Free Certificate`,
          loading: freeSslLoading || sslRenewBusy,
          disabled: sslCheckLoading || isCustomSslInstalled || customSslLoading,
          classes: 'success--btn',
          onClick: () => onCreateSsl(website)
        }) : null}
        {!nextBtnHandler && JsxHelper.createButton({
          label: `${isCustomSslInstalled ? 'Renew' : 'Install'} Custom Certificate`,
          loading: customSslLoading,
          disabled: sslCheckLoading || freeSslLoading || sslRenewBusy || revokeSslLoading,
          classes: 'alt--btn',
          onClick: () => toggleInstallCustomSslModal(true)
        })}
        {isCustomSslInstalled && JsxHelper.createButton({
          label: `Uninstall Custom Certificate`,
          loading: revokeSslLoading,
          disabled: sslCheckLoading || freeSslLoading || sslRenewBusy || customSslLoading,
          onClick: onRevokeCustomSsl,
        })}
        {nextBtnHandler ? JsxHelper.createButton({
          label: 'Next',
          disabled: freeSslLoading || sslCheckLoading || sslRenewBusy,
          onClick: nextBtnHandler,
        }) : null}
      </div>
    </DomainStep>
    {installCustomSslModal && DialogHelper.inputs({
      title: 'Upload Custom SSL Certificate',
      icon: 'create',
      iconColor: 'success',
      btnText: 'Upload',
      onConfirm: handleSubmit(() => onCreateSsl(website, true)),
      onClose: () => toggleInstallCustomSslModal(false),
      register,
      inputs: [{
        name: 'certificate',
        label: 'Signed Certificate (e.g. certificate.crt)',
        placeholder: `Copy and paste the signed certificate here`,
        value: installCustomSslModal.certificate,
        type: 'textarea',
        required: true,
        onChange: onChangeCustomSslField,
        errors,
      }, {
        name: 'private_key',
        label: 'Private Key (e.g. private.key)',
        placeholder: `Copy and paste the private key here`,
        value: installCustomSslModal.private_key,
        type: 'textarea',
        onChange: onChangeCustomSslField,
        required: true,
        errors,
      }, {
        name: 'ca_bundle',
        label: 'CA Bundle (optional)',
        placeholder: `Copy and paste the CA bundle here`,
        value: installCustomSslModal.ca_bundle,
        type: 'textarea',
        onChange: onChangeCustomSslField,
        errors,
      }]
    })}
  </Fragment>);
}

export default SslStep;