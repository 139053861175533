import { isEmpty } from 'helpers';
import UserHelper from './user';

const featureNameToText = (name) => {
  if (name === 'Staging environments') {
    return 'Staging sites';
  }
  return name;
}

const featureValueToText = (feature) => {
  const code = feature.code;
  let limit = feature.limit;
  let unit = feature.unit;
  let text = '';
  let limitFactor = parseFloat(limit);
  if (feature.value === 'unlimited' || unit === null) { // e.g. bandwith, instance type.
    return feature.value;
  } else if (Number.isInteger(feature.value)) { // e.g. visits.
    text = feature.value;
  } else if (unit === '256 MB') { // e.g. php maxmem.
    text = (limitFactor * 256) + ' MB';
  } else if (code.includes('_id')) {
    text = feature.value;
  } else { // e.g. cpu usage.
    text = limitFactor + ' ' + unit;
  }
  return text;
}

const featureUsageToIconDetails = (item) => {
  const result = {
    icon: 'trendUp',
    color: 'success',
    tooltip: 'Your usage is trending up but you are not close to your limit'
  };
  if (item.percent >= 100) {
    result.color = 'danger';
    result.tooltip = 'Your usage is trending up and you are at your limit which might incur additional charges';
  } else if (item.percent >= 85) {
    result.color = 'warning';
    result.tooltip = 'Your usage is trending up and you are close to your limit';
  }
  return result;
}

const sortActiveFirst = data => {
  if (data && !isEmpty(data)) {
    const activeData = data.filter(el => el.status === 'active');
    const restOfData = data.filter(el => el.status !== 'active');
    return [...activeData, ...restOfData]
  }else{
    return [];
  }
}

const getSubscriptionStatusColor = status => {
  let res = '';
  switch (status) {
    case 'not-started':
    case 'inactive':
      res = 'primary';
      break;
    case 'canceled':
      res = 'warning';
      break;
    case 'active':
      res = 'success';
      break;
    case 'ended':
      res = 'danger';
      break;
    default:
      res = 'primary'
  }
  return res;
}
// Filter features by interface.
const filterFeaturesByUserRole = features =>
  features.filter(
    f =>
    !f.interface ||
    f.interface === 'all' ||
    (f.interface === 'admin' && UserHelper.isAdmin()) ||
    (f.interface === 'partner' && UserHelper.isPartner())
  );

// Filter features by subcategory
const filterFeaturesBySubcategory = (features, subcategory) =>
  features.filter(
    f =>
      !f.subcategory ||
      (f.subcategory === subcategory.slug)
  );

// Return feature value.
const getFeatureValue = (value, feature) => {
  let _value = value;
  if (feature.type === 'limit') {
    if (feature.limit <= 0) {
      _value = 'unlimited';
    } else if (isNaN(feature.unit)) {
      _value = `${feature.limit} ${feature.unit}`;
    } else {
      _value = feature.limit * feature.unit;
    }
  } else if (feature.type === 'basic') {
    if (!value) {
      _value = 'Not configured';
    }
    if (Array.isArray(value)) {
      // Multiple options.
      _value = value.join(' / ');
    } else {
      if (feature.unit && !isNaN(feature.unit)) {
        _value = value * feature.unit;
      }
    }
  }
  return typeof _value === 'string' ? _value.replace(/^0+/, '') : _value;
};

const filterSubscribableWebsitePlans = (plans, activeSubs, partnerSlug) => {
  // Get active subscriptions to upgrade-only plans
  const upgradeOnlySubs = activeSubs.filter(s => {
    const plan = plans.find(p => s.plan_slug === p.slug);
    return plan.upgrade_only;
  });
  // Filter global plans and partner plans if partner is specified
  const selectedPlans = partnerSlug ? plans.filter(
    p => !p.assignee_slug || p.assignee_slug === partnerSlug,
  ) : plans;
  // Filter subscribable plans
  return selectedPlans.map(p => {
    // Set select status
    p.select_disable = false;
    p.select_disable_reason = null;
    // If a plan allow multi-subscription we don't need to filter
    if (p.allow_multi_subs) {
      return p;
    }
    // Plans with upgrade only flag can only have one active subscription
    // within the set of plans inside the same subcategory.
    for (const s of upgradeOnlySubs) {
      if (s.plan_slug === p.slug) {
        return p;
      } else if (_plansInSameSubcategory(plans, s.plan_slug, p.slug)) {
        p.select_disable = true;
        p.select_disable_reason = 'Upgrade only plan with an active subscription in the same plan subcategory.';
        return p;
      }
    }
    // Plans without allow multi subscription flag must have at least one
    // active subscription in order to become subscribable.
    let hasActiveSub = false;
    for (const s of activeSubs) {
      if (s.plan_slug === p.slug) {
        hasActiveSub = true;
      }
    }
    if (!hasActiveSub) {
      p.select_disable = true;
      p.select_disable_reason = 'An active subscription is required.';
    }
    return p;
  });
}

// Check whether the plans are in the same plan subcategory
const _plansInSameSubcategory = (plans, slug1, slug2) => {
  const plan1 = plans.find(p => slug1 === p.slug);
  const plan2 = plans.find(p => slug2 === p.slug);
  return plan1.subcategory_slug === plan2.subcategory_slug;
};

const PlanHelper = {
  sortActiveFirst,
  getSubscriptionStatusColor,
  filterFeaturesByUserRole,
  getFeatureValue,
  filterFeaturesBySubcategory,
  filterSubscribableWebsitePlans,
  featureValueToText,
  featureNameToText,
  featureUsageToIconDetails,
};

export default PlanHelper;