import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import GoogleAnalyticsService from 'services/google-analytics';
import GoogleAnalyticsHelper from 'helpers/googleAnalytics';
import { googleAnalyticsClientIdSelector, googleAnalyticsRedirectUri  } from 'store/platform/platformSelectors';
import useTitle from 'hooks/useTitle';
import JsxHelper from 'helpers/jsx';
import env from 'config/env';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import DialogHelper from 'helpers/dialog';
import 'components/stepper/stepper.css';
import { displayResponseError, openLink, isEmptyOrNull } from 'helpers';
import useModal from 'hooks/useModal';
import useConfirm from 'hooks/useConfirm';
import { useQueryResult } from 'hooks/useQuery';
import { websitesSelector } from 'store/website/websiteSelectors';import {
  setGlobalPleaseWaitMsg,
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
} from 'store/global/globalActions';
import LocalStorageHelper from 'helpers/localStorage';
import WebsiteHelper from 'helpers/website';
import { useHistory } from 'react-router-dom';

const GoogleAnalytics = () => {
  useTitle(GoogleAnalyticsHelper.SERVICE_NAME);
  const BREADCRUMBS = JsxHelper.createBreadcrumbs(GoogleAnalyticsHelper.SERVICE_NAME, 'settings');
  const GA_CLIENT_ID = useSelector(googleAnalyticsClientIdSelector);
  const GA_REDIRECT_URI = useSelector(googleAnalyticsRedirectUri);
  const allWebsites = useSelector(websitesSelector);
  const redirectURI = GoogleAnalyticsHelper.createRedirectUri(GA_CLIENT_ID, GA_REDIRECT_URI);
  const [tableLoading, setTableLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const modalDialog = useModal();
  const [accounts, setAccounts] = useState([]);
  const [currentScreen, setCurrentScreen] = useState(''); // accounts, websites, properties
  const [currentAccount, setCurrentAccount] = useState(null); // {guid: '', email: ''}
  const confirm = useConfirm();
  const history = useHistory();
  const query = useQueryResult();
  const dispatch = useDispatch();

  // ------------------------------
  // Lifecycle
  // ------------------------------

  useEffect(() => {
    if (query.scope && query.code) {
      handleOAuthConnectResponse(query.code);
    } else {
      fetchAccounts();
    }
    // eslint-disable-next-line
  }, []);

  // ------------------------------
  // Helpers
  // ------------------------------

  const handleErrorResponse = (error) => displayResponseError(modalDialog, error);

  // ------------------------------
  // API
  // ------------------------------

  const handleOAuthConnectResponse = (code) => {
    // After the user has connected their Google Analytics account, they are redirected to this page
    // with the code and state parameters in the URL. The code will be sent to the backend to create
    // a new account in the database with the user's Google Analytics account details. If the account
    // already exists, an error will be thrown.
    setCurrentScreen('oauth-redirect');
    GoogleAnalyticsService.connectAccount({ code: query.code })
      .then((accounts) => {
        setAccounts(accounts);
        setCurrentScreen('accounts');
        DialogHelper.success(
          modalDialog,
          'Account Connected',
          'Your Google Analytics account has been successfully connected.'
        );
        const redirectTo = LocalStorageHelper.read(GoogleAnalyticsHelper.REDIRECT_TO_CACHE_KEY);
        if (redirectTo) {
          window.logHelper.info(`Redirecting back to ${redirectTo} after connecting Google Analytics account`);
          LocalStorageHelper.remove(GoogleAnalyticsHelper.REDIRECT_TO_CACHE_KEY);
          WebsiteHelper.goTo(history, redirectTo, 'google-analytics');
        }
      })
      .catch((error) => {
        fetchAccounts(); // Refresh the accounts list
        handleErrorResponse(error);
      });
  };

  const fetchAccounts = () => {
    setTableLoading(true);
    setCurrentScreen('accounts');
    GoogleAnalyticsService.listAccounts()
      .then(data => setAccounts(data))
      .catch(handleErrorResponse)
      .finally(() => setTableLoading(false))
  };

  const disconnectAccount = account => {
    setTableLoading(true);
    GoogleAnalyticsService.disconnectAccount({ account_id: account.account_id })
      .then(() => setAccounts(prev => prev.filter(item => item.account_id !== account.account_id)))
      .catch(handleErrorResponse)
      .finally(() => setTableLoading(false))
  }

  const disconnectProperty = (property) => {
    const data = { website_slug: property.website_slug, property_id: property.property_id };
    dispatch(setGlobalPleaseWaitMsg({ id: property.display_name, model: 'Google Analytics Property', action: 'disconnected' }));
    GoogleAnalyticsService.disconnectWebsiteProperty(data)
      .then(() => {
        setAccounts(prev => prev.map(account => {
          if (account.account_id === property.account_id) {
            account.connected_websites = account.connected_websites.filter(website => website.property_id !== property.property_id);
          }
          return account;
        }));
        setCurrentAccount(prev => {
          prev.connected_websites = prev.connected_websites.filter(website => website.property_id !== property.property_id);
          return prev;
        });
        dispatch(setGlobalSuccessMsg({ id: property.display_name, model: 'Google Analytics Property', action: 'disconnected' }));
      })
      .catch(err => dispatch(setGlobalErrorMsg(err)))
  }

  // ------------------------------
  // Property Table
  // ------------------------------

  const propertyActions = [
    {
      value: 'Disconnect',
      onClick: item => DialogHelper
        .confirmAction(confirm, 'disconnect', item.display_name, 'Website', `This will disconnect your website from the ${env.getBrandShortName()} dashboard, but your Google Analytics property will remain intact.`)
        .then(() => disconnectProperty(item))
    },
  ];

  const propertyHeaders = [
    JsxHelper.createTableWebsiteHeader(allWebsites, '50%'),
    JsxHelper.createTableTextHeaderWithCallback('display_name', 'Property', '40%', (property) => {
      return JsxHelper.createTableMultiLineCell({
        header: property.display_name,
        subheader: `ID: ${property.property_id}`,
      })
    }),
    JsxHelper.createTableActionsHeader(propertyActions, '10%'),
  ];

  // ------------------------------
  // Account Table
  // ------------------------------

  const accountActions = [
    {
      value: 'Connected Websites',
      onClick: item => {
        setCurrentAccount(item);
        setCurrentScreen('websites');
      }
    },
    {
      value: 'Disconnect',
      onClick: item => DialogHelper
        .confirmAction(confirm, 'disconnect', item.email, `${GoogleAnalyticsHelper.SERVICE_NAME} Account`, `This will disconnect your account from the ${env.getBrandShortName()} dashboard and all connected websites, but your Google Analytics account will remain intact.`)
        .then(() => disconnectAccount(item))
    },
  ];

  const accountHeaders = [
    {
      name: 'Account',
      selector: 'email',
      searchable: true,
      width: '50%',
      cell: row => JsxHelper.createTableMultiLineCell({
        header: row.email,
        subheader: `ID: ${row.account_id}`,
      })
    },
    JsxHelper.createTableTextHeaderWithCallback('type', 'Summary', '30%', (account) => {
      if (isEmptyOrNull(account.properties)) return 'No connected websites';
      return `${account.connected_websites.length} website(s) connected`;
    }),
    JsxHelper.createTableActionsHeader(accountActions, '20%'),
  ];

  return (
    <div className='global-settings'>
      <TitleBar className='titlebar'>
        <TitleBar.Title breadcrumbs={BREADCRUMBS}>{`${GoogleAnalyticsHelper.SERVICE_NAME} Accounts`}</TitleBar.Title>
        <TitleBar.Actions>
          {currentScreen === 'accounts' && JsxHelper.createButton({
            label: 'Connect Account',
            onClick: () => setModal({ name: 'create-account', })}
          )}
          {currentScreen === 'accounts' ? JsxHelper.createBackButton() : JsxHelper.createButton({
            label: 'Back',
            onClick: () => setCurrentScreen('accounts')
          })}
        </TitleBar.Actions>
      </TitleBar>
      {currentScreen === 'accounts' && <p className='color-primary subheader'>
        {env.getBrandShortName()} offers a seamless integration with <b>{JsxHelper.createLink('https://google.com/analytics', 'Google Analytics')}</b> to provide you with detailed insights into your website's traffic data.
      </p>}
      <Content>
        {currentScreen === 'accounts' && <div id='ga-accounts' style={{width: '60%'}}>
          <WPSDataTable columns={accountHeaders} body={accounts} noSearchOnTable={true} hidePagination={true} loading={tableLoading} />
        </div>}
        {currentScreen === 'websites' && <div id='ga-websites' style={{width: '75%'}}>
          <WPSDataTable columns={propertyHeaders} body={currentAccount.connected_websites} noSearchOnTable={true} hidePagination={true} loading={tableLoading} />
        </div>}
        {currentScreen === 'oauth-redirect' && <p>Connecting your Google Analytics account...</p>}
      </Content>
      {modal && modal.name === 'create-account' && DialogHelper.inputs({
        title: `Connect ${GoogleAnalyticsHelper.SERVICE_NAME} Account`,
        className: 'instruct-modal',
        onClose: () => setModal(false),
        onConfirm: () => openLink(redirectURI),
        icon: 'googleAnalytics',
        iconColor: 'info',
        confirmBtn: 'Connect Account',
        confirmColor: 'success',
        header: GoogleAnalyticsHelper.renderConnectSteps(),
        inputs: []
      })}
    </div>
  );
};

export default GoogleAnalytics;
