import React, { Fragment, useState } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { useDispatch } from 'react-redux';
import WebsiteHelper from 'helpers/website';
import TableHelper from 'helpers/table';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import CloudflareHelper from 'helpers/cloudflare';
import CloudflareService from 'services/cloudflare';
import { getErrorMsg, isArray, isObject } from 'helpers';
import { setGlobalErrorMsg, setGlobalSuccessMsg, setGlobalPleaseWaitMsg } from 'store/global/globalActions';
import { syncWebsiteCloudflareZone, updateWebsiteCloudflareZoneUnderAttackMode } from 'store/website/websiteActions';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import useModal from 'hooks/useModal';
import './cssFiles/cdn.css';

const CloudflareZoneDetails = ({ website }) => {
  useTitle('Website Cloudflare Details');

  const dispatch = useDispatch();
  const modalDialog = useModal();
  const [loading, setLoading] = useState(false);
  const createCloudflareLink = (path) => `https://dash.cloudflare.com/${path}`;
  const createCloudflareZoneLink = () => createCloudflareLink(`${zone.account_id}/${zone.name}`);
  const [zoneSettings, setZoneSettings] = useState(null);
  const [zoneDNSRecords, setZoneDNSRecords] = useState(null);
  const zone = WebsiteHelper.getCloudflareZone(website);

  // --------------------------------------------------------
  // HANDLERS
  // --------------------------------------------------------

  const fetchDNSRecords = () => {
    if (zoneDNSRecords || loading === 'dns-records') {
      window.logHelper.warn('DNS records are already loaded or in progress.');
      return;
    }
    setLoading('dns-records');
    CloudflareService.listZoneDNSRecords({ zone_id: zone.zone_id, website_slug: website.slug })
      .then((records) => {
        setZoneDNSRecords(records);
      })
      .catch(handleErrorResponse)
      .finally(() => setLoading(false));
  }

  const handleErrorResponse = (error) => {
    dispatch(setGlobalErrorMsg(error));
    DialogHelper.error(modalDialog, getErrorMsg(error));
  }

  const toggleUnderAttack = () => {
    setLoading('under-attack');
    dispatch(updateWebsiteCloudflareZoneUnderAttackMode({ website_slug: website.slug, zone_id: zone.zone_id, under_attack: !zone.under_attack }))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'under attack mode', id: zone.name }))
        DialogHelper.success(modalDialog, 'Under Attack Mode', `The under attack mode has been ${zone.under_attack ? 'disabled' : 'enabled'}.`)
      })
      .catch(handleErrorResponse)
      .finally(() => setLoading(false));
  }

  const checkNameservers = () => {
    setLoading('check');
    const currentStatus = zone.status;
    dispatch(syncWebsiteCloudflareZone({ website_slug: website.slug, zone_id: zone.zone_id }))
    .then((website) => {
      const zone = WebsiteHelper.getCloudflareZone(website);
      if (zone.status === 'pending') {
        DialogHelper.warning(
          modalDialog,
          'Nameservers Update Required',
          `Follow the instructions to update your domain's nameservers or wait for the changes to propagate if you've already updated them.`
        );
      } else if (zone.status === 'active') {
        DialogHelper.success(
          modalDialog,
          'Nameservers Verified',
          'Your domain is ' + (currentStatus === 'pending' ? 'now' : 'already') + ' using Cloudflare nameservers.'
        );
      }
    })
    .finally(() => setLoading(false));
  }

  // --------------------------------------------------------
  // RENDER
  // --------------------------------------------------------

  const renderNSValues = (style) => <span style={{ width: '10%', display: 'inline-block' }}>
    {zone.name_servers.map(nsValue => (
      <div className='ns-record' key={nsValue} style={style||{}}>
        {JsxHelper.createCopyButton({ dispatch, value: nsValue, type: 'NS Record' })}
      </div>
    ))}
  </span>

  const renderDNSRecords = () => {
    const headers = [
      JsxHelper.createTableTextHeaderWithCallback('', ' ', '9%', row => <strong>{row.type}</strong>),
      JsxHelper.createTableTextHeaderWithCallback('name', 'Record', '37%', row => {
        row = TableHelper.customizeCellValue(row, 'name', row.name);
        return <div style={{ margin: '6px 0' }}>
          {JsxHelper.createCopyButton({ 
            dispatch, 
            value: row.name,
            type: 'DNS Record Name',
            background: 'info',
            small: true,
            labelLimit: 50,
          })}
        </div>
      }),
      JsxHelper.createTableTextHeaderWithCallback('content', 'Value', '38%', row => {
        row = TableHelper.customizeCellValue(row, 'value', row.value);
        return <div style={{ margin: '6px 0' }}>
          {JsxHelper.createCopyButton({ 
            dispatch, 
            value: row.content,
            type: 'DNS Record Value',
            background: 'primary',
            small: true,
            labelLimit: 50,
          })}
        </div>
      }),
      JsxHelper.createTableTextHeaderWithCallback('ttl', 'TTL', '8%', row => (
        row.ttl === 1 ? 'Auto' : `${row.ttl}s`
      )),
      JsxHelper.createTableTextHeaderWithCallback('proxied', 'Status', '8%', row => (
        row.proxiable ? (
          JsxHelper.createBubble({
            text: row.proxied ? 'Proxied' : 'DNS only',
            background: row.proxied ? 'success' : 'info',
            small: true
          })
        ) : '—'
      ))
    ];
  
    return (
      <WPSDataTable columns={headers} body={zoneDNSRecords} dataKey="id" loading={loading === 'dns-records'} />
    );
  };

  return (
  <Container className='margin-0'>
    <TitleBar className='titlebar'>
    <TitleBar.Title>{zone.name}</TitleBar.Title>
    </TitleBar>
      <p className='color-primary subheader'>
        Monitor and the status of your {CloudflareHelper.LABEL_DNS_SERVICE} and verify the nameservers.<br/>
        Check out our {JsxHelper.createFaqLink('cloudflare-integration', 'knowledge base')} to learn more how the integration works.
      </p>
    <Content>
    {zone.status === 'active' && (<Fragment>
      {JsxHelper.createSuccessBox(<span>Your {CloudflareHelper.LABEL_DNS_SERVICE} is active and ready to use.
        <b>{' '}<span className='goto-link' onClick={checkNameservers}>{loading === 'check' ? 'Checking...' : 'Check again.'}</span></b>
      </span>)}
      {!zoneDNSRecords && JsxHelper.createDetailsTable(zoneSettings ? zoneSettings : [
        { label: 'Status',      value: JsxHelper.createBubble({ text: 'Active', background: 'success' }) },
        { label: 'Nameservers', value: renderNSValues({ margin: '5px 0' }) },
      ])}
      {zoneDNSRecords && renderDNSRecords()}
    </Fragment>)}
    {zone.status === 'pending' && (<Fragment>
      {JsxHelper.createWarningBox(<span>Your {CloudflareHelper.LABEL_DNS_SERVICE} is pending verification.</span>, false, 'ACTION REQUIRED')}
      <div className='ns-steps'>
        <p>Follow the <a className='goto-link' target="__blank" href={createCloudflareZoneLink()}>official Cloudflare instructions</a> to update the nameservers or follow the steps below:</p>
        <p>1. <strong>Find out</strong> the registrar of {<b>{zone.name}</b>} via {JsxHelper.createToolboxLink(zone.name)}.</p>
        <p>2. <strong>Log in</strong> to your account and go to the DNS Manager.</p>
        <div>
          <p>3. <strong>Add</strong> the following nameservers:<br/></p>
            {renderNSValues()}
        </div>
        <p>4. <strong>Save</strong> your changes.</p>
        <p>5. <strong>Click</strong> the button below to run the validation check against Cloudflare.</p>
        <p className='step-note'>Note that it may take up to 24 hours for the changes to propagate.</p>
      </div>
    </Fragment>)}
    <div className='action-buttons display-flex-nowrap' style={{display: 'flex'}}>
      {!zoneDNSRecords && !zoneSettings && zone.status === 'active' ? JsxHelper.createButton({
        label: 'Load Settings',
        onClick: () => {
          setLoading('settings');
          CloudflareService.getZoneSettings({ zone_id: zone.zone_id, website_slug: website.slug })
            .then((settings) => {
              setZoneSettings(settings
                .filter(setting => !isArray(setting.value) && !isObject(setting.value))
                .map(setting => ({ label: setting.label, value: setting.value }))
              );
            })
            .catch(handleErrorResponse)
            .finally(() => setLoading(false));
        },
        loading: loading === 'settings',
        disabled: loading && loading !== 'settings',
        classes: 'margin-left-6 alt--btn'
      }) : null}
      {zoneSettings ? JsxHelper.createButton({
        label: 'Hide Settings',
        onClick: () => setZoneSettings(null),
        classes: 'margin-left-6 primary--btn'
      }) : null}
      {zone.status === 'pending' ? JsxHelper.createButton({
        label: 'Verify Nameservers',
        onClick: checkNameservers,
        loading: loading === 'check',
        classes: 'margin-left-6 primary--btn'
      }) : null}
      {!zoneSettings && !zoneDNSRecords && zone.status === 'active' ? JsxHelper.createButton({
        label: 'Load DNS Records',
        onClick: fetchDNSRecords,
        loading: loading === 'dns-records',
        disabled: loading && loading !== 'dns-records',
        classes: 'primary--btn'
      }) : null}
      {zoneDNSRecords ? JsxHelper.createButton({
        label: 'Hide DNS Records',
        onClick: () => setZoneDNSRecords(null),
        classes: 'margin-left-6 warning--btn'
      }) : null}
      {zone.status === 'active' && !zoneDNSRecords ? JsxHelper.createButton({
        label: 'Purge Cache',
        onClick: () => {
          setLoading('purge');
          dispatch(setGlobalPleaseWaitMsg({ model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'cache purged', id: zone.name }));
          CloudflareService.purgeZoneCache({ zone_id: zone.zone_id, website_slug: website.slug })
            .then(() => {
              dispatch(setGlobalSuccessMsg({ model: CloudflareHelper.LABEL_WEBSITE_SERVICE, action: 'cache purged', id: zone.name }))
              DialogHelper.success(modalDialog, 'Cached Purged', 'Please allow a few seconds for changes to take effect.')
            })
            .catch(handleErrorResponse)
            .finally(() => setLoading(false));
        },
        loading: loading === 'purge',
      }) : null}
      {zone.status === 'active' && !zoneSettings && !zoneDNSRecords && JsxHelper.createButton({
        label: `${zone.under_attack ? 'Disable' : 'Enable'} Under Attack Mode`,
        onClick: toggleUnderAttack,
        loading: loading === 'under-attack',
        classes: zone.under_attack ? 'primary--btn' : 'danger--btn'
      })}
    </div>
    </Content>
  </Container>
  );
};

export default CloudflareZoneDetails;
