import React, { Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import BillingAnalytics from 'components/billing/billingAnalytics/billingAnalytics';
import JsxHelper from 'helpers/jsx';

const BusinessPerformance = () => {
  useTitle('Business Performance');

  const BREADCRUMBS = JsxHelper.createBreadcrumbs(`Business Performance`, 'reports');

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={BREADCRUMBS}>Business Performance</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton('/reports')}
        </TitleBar.Actions>
      </TitleBar>
      <Content style={{ margin: '40px 50px' }}>
        <BillingAnalytics/>
      </Content>
    </Fragment>
  );
}

export default BusinessPerformance;
