import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { partnersSelector } from 'store/user/userSelectors';
import JsxHelper from 'helpers/jsx';
import ReportsService from 'services/report';
import BillingService from 'services/billing';
import ArrayHelper from 'helpers/array';
import DialogHelper from 'helpers/dialog';
import useModal from 'hooks/useModal';
import { useDispatch } from 'react-redux';
import { getErrorMsg } from 'helpers';
import { setGlobalErrorMsg } from 'store/global/globalActions';

const ClientInvoices = () => {
  const BREADCRUMBS = JsxHelper.createBreadcrumbs('Client Invoices', 'reports');
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const allPartners = useSelector(partnersSelector);
  const modalDialog = useModal();
  const [selectedPartner, setSelectedPartner] = useState("");
  const [monthsAgo, setMonthsAgo] = useState(3);
  const [downloadLoading, setDownloadLoading] = useState(false);
    const dispatch = useDispatch();

  const handleSelectPartner = e => {
    const { value } = e.target;
    setSelectedPartner(!value ? null : value);
    refreshReport(value);
  };

  const handleSelectMonthsAgo = e => {
    const { value } = e.target;
    setMonthsAgo(value);
    if (selectedPartner) {
      refreshReport(selectedPartner);
    }
  };

  const refreshReport = async (partnerSlug) => {
    if (!partnerSlug) {
      return;
    }
    setLoading(true);
    const data = { partner_slug: partnerSlug, months_ago: monthsAgo };
    ReportsService.lisClientInvoices(data)
      .then((response) => setTableData(response))
      .catch((err) => DialogHelper.error(modalDialog, getErrorMsg(err)))
      .finally(() => setLoading(false));
  };

  const actions = [
    {
      value: 'Download',
      onClick: item => {
        const data = { file_name: item.id, guid: item.guid };
        if (!downloadLoading) {
          setDownloadLoading(true);
          BillingService.downloadInovice(data)
            .catch(err => dispatch(setGlobalErrorMsg(err)))
            .finally(() => setDownloadLoading(false));
        }
      },
    },
  ];

  const headers = [
    JsxHelper.createTableTimeHeader('created_at'),
    JsxHelper.createTableTextHeader('payer', 'Client', '25%', '-'),
    JsxHelper.createTableTextHeader('id', 'Invoice ID', '12%', '-', true),
    {
      name: 'Billing Period',
      selector: 'period_start_date',
      searchable: true,
      sortable: true,
      width: '18%',
      cell: row => JsxHelper.createTableCellFromTo({
        from: row.period_start_date ? row.period_start_date : null,
        to: row.period_end_date ? row.period_end_date : null
      }),
    },
    JsxHelper.createTableTextHeaderWithCallback('total', 'Total', '12%', (row) => `${row.total} ${row.currency}`),
    JsxHelper.createTableBinaryBubbleHeader('is_paid', 'Paid', '8%'),
    JsxHelper.createTableActionsHeader(actions, '13%')
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={BREADCRUMBS}>Client Invoices</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createSelectInput({
            name: 'partner_slug_option',
            value: selectedPartner,
            options: ArrayHelper.buildSelectOptions(allPartners, 'display_name', 'slug'),
            isSearchable: true,
            onChange: handleSelectPartner,
            placeholder: 'Select partner...',
            sortOff: true
          })}
          {JsxHelper.createSelectInput({
            name: 'months_ago_option',
            value: monthsAgo,
            options: ArrayHelper.buildSelectOptions([
              { value: 1, label: 'Last month only' },
              { value: 3, label: 'Last 3 months' },
              { value: 6, label: 'Last 6 months' },
              { value: 12, label: 'Last 12 months' }
            ], 'label', 'value'),
            isSearchable: true,
            onChange: handleSelectMonthsAgo,
            sortOff: true
          })}
          {JsxHelper.createBackButton('/reports')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        {selectedPartner && <WPSDataTable loading={loading} columns={headers} body={tableData} />}
        {!selectedPartner && (
          <div class='select-data-required'>
            <h4>Select a partner to view their invoices.</h4>
          </div>
        )}
      </Content>
    </Fragment>
  );
};

export default ClientInvoices;
