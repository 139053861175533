import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { WPSForm } from 'styles/layout/forms';
import Modal from 'components/layout/persistentmodal';
import { useDispatch } from 'react-redux';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { updateEmailTemplates } from 'store/setting/settingActions';
import Tabs, { Tab, TabPanel } from 'components/wpstaq/tabs';
import JsxHelper from 'helpers/jsx';

const EditEmailTemplate = ({ show, value, onClose }) => {
  const dispatch = useDispatch();
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [actions, setActions] = useState([]);
  const [submitting, setSubmit] = useState(false);

  // Dismiss modal and reset state on modal close.
  const handleModalClose = () => {
    if (onClose) onClose();
    setContent(null);
    setSubject('');
  };

  const updateTemplate = () => {
    setSubmit(true);
    const data = {
      key: value.key,
      content: content,
      subject: subject || value.subject,
    };
    dispatch(updateEmailTemplates(data))
      .then(() => {
        handleModalClose();
        dispatch(setGlobalSuccessMsg({ model: 'Email template', action: 'updated' }));
      })
      .finally(() => setSubmit(false));
  };

  useEffect(() => {
    if (show && !content) {
      setContent(value.content);
      // setTemplate({ ...value });
    }

    // eslint-disable-next-line
  }, [show, value]);

  const bodyVars = value && value.body_vars;

  useEffect(() => {
    if (bodyVars) {
      const keys = Object.keys(bodyVars);
      const newActions = keys.map(k => {
        return {
          label: k,
          value: `{${k}}`,
          tooltip: bodyVars[k],
        };
      });
      setActions(newActions);
    }
    // eslint-disable-next-line
  }, [bodyVars]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
  }, []);

  return (
    <Modal
      className="TinyMCE-tags"
      title='Edit Template'
      onClose={handleModalClose}
      onConfirm={updateTemplate}
      maxWidth='80vw'
      width='100%'
      loading={submitting}
      persistent
      open={show}>
      <WPSForm onSubmit={handleSubmit}>
        <Tabs
          initialValue='body'
          tabs={() => (
            <Fragment>
              <Tab name='body'>Body</Tab>
            </Fragment>
          )}
          panels={() => (
            <Fragment>
              <TabPanel name='body'>
                {JsxHelper.createTextInput({
                  label: 'Email Subject',
                  name: 'subject',
                  value: value && subject.length === 0 ? value.subject : subject,
                  onChange: (e) => setSubject(e.target.value),
                  style: { width: '100%' },
                  required: true,
                })}
                {JsxHelper.createTinyMCEInput({
                  label: 'Email Content',
                  name: 'content',
                  value: content,
                  onChange: setContent,
                  actions,
                  minHeight: 300
                })}
              </TabPanel>
            </Fragment>
          )}
        />
      </WPSForm>
    </Modal>
  );
};

export default EditEmailTemplate;
