import React, { useState, useMemo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { isEmptyOrNull } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWebsite, updateWebsiteLabel, fetchWebsite, changeWebsitePartnerPlan } from 'store/website/websiteActions';
import { setGlobalSuccessMsg, setGlobalPleaseWaitMsg } from 'store/global/globalActions';
import { globalWebsiteLabelGroups } from 'store/setting/settingSelectors';
import { plansByCategoryType } from 'store/billing/billingSelectors';
import useConfirm from 'hooks/useConfirm';
import UserHelper from 'helpers/user';
import WebsiteHelper from 'helpers/website';
import { Container } from 'styles/layout/tables';
import WPSDataTable from '../../components/wpstaq/WPSDataTable/WPSDataTable';
import TableHelper from 'helpers/table';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import DateHelper from 'helpers/date';
import PlanHelper from 'helpers/plan';
import { useForm } from 'react-hook-form';
import useModal from 'hooks/useModal';
import './website.css';
import ArrayHelper from 'helpers/array';
import WebsiteService from 'services/website';

const TableView = ({ websites, loading }) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [modal, setModal] = useState(false);
  const modalDialog = useModal();
  const globalLabelGroups = useSelector(globalWebsiteLabelGroups);
  const [loadingWhileLogin, setLoadingWhileLogin] = useState([]);
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const partnerWebsitePlans = useSelector(plansByCategoryType('website'));

  const viewWebsiteProfile = slug => `${path}/${slug}/overview`;
  const openWebsiteProfile = (slug, page) => history.push(`${path}/${slug}/${page || 'overview'}`);
  const openPlansPage = () => history.push('/billing/plans');
  const openWhiteLabelPage = () => history.push('/settings/website-label-groups');

  const handleDeleteWebsite = website => {
    dispatch(setGlobalPleaseWaitMsg({ id: WebsiteHelper.getLabel(website), model: 'website', action: 'deleted' }));
    dispatch(deleteWebsite(website)).then(() => dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'website', action: 'deleted' })));
  };

  // --------------------------------------------------
  // API CALLS
  // --------------------------------------------------

  const handleEditLabel = () => {
    setModal({ ...modal, loading: true });
    const data = {
      website_slug: modal.website_slug,
      label: modal.label,
      label_group_slug: modal.label_group_slug === '-' ? null : modal.label_group_slug,
    }
    dispatch(updateWebsiteLabel(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: modal.website_slug, model: 'website label', action: 'updated' }))
        setModal(false);
      }).catch(() => setModal({ ...modal, loading: false }));
  }

  const handleChangePlan = () => {
    setModal({ ...modal, loading: true });
    const data = {
      website_slug: modal.website_slug,
      new_plan_slug: modal.new_plan_slug,
    }
    dispatch(changeWebsitePartnerPlan(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: modal.website_slug, model: 'website plan', action: 'changed' }))
        setModal(false);
      }).catch(() => setModal({ ...modal, loading: false }));
  }

  const onRefreshWebsite = (website) => {
    const data = {
      website_slug: website.slug,
      check_status: true,
      check_hosting_provider: true
    }
    dispatch(setGlobalPleaseWaitMsg({ id: WebsiteHelper.getLabel(website), model: 'website', action: 'refreshed' }));
    dispatch(fetchWebsite(data))
    .then(() => dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'website', action: 'refreshed' })))
  }

// --------------------------------------------------
// TABLE
// --------------------------------------------------

const _renderDetailsTable = (details) => 
  <table className="table">
    <tbody>
      {details.filter(record => record.value !== false).map((record, index) => (
        <tr key={index}>
          <th style={{ textAlign: 'left', fontWeight: 'bold' }}>{record.title}</th>
          <td style={{ textAlign: 'left' }}>{record.value}</td>
        </tr>
      ))}
    </tbody>
  </table>

const openDetailsTable = (modalDialog, website) =>
  DialogHelper.html(modalDialog, _renderDetailsTable([
    { title: 'ID', value: website.slug },
    { title: 'Label', value: website.label || 'N/A' },
    { title: 'Domain', value: website.default_domain },
    { title: 'Server', value: website.server_slug },
    { title: 'Partner', value: website.partner_slug },
    { title: 'Database', value: website.database_name || false },
    { title: 'Environment', value: WebsiteHelper.getEnvName(website) },
    { title: 'Create Time', value: DateHelper.toHumanDateTime(website.created_at) },
    { title: 'Go Live Time', value: website.went_live_at ? DateHelper.toHumanDateTime(website.went_live_at) : false },
  ]), {
    hideCloseBtn: true,
  });

  const actions = [
    {
      value: 'Details',
      onClick: website => openDetailsTable(modalDialog, website),
      doHide: () => !UserHelper.isAdminOrAgent(),
    },
    {
      value: 'Edit Label',
      onClick: website => setModal({
        id: 'edit-label',
        website_slug: website.slug,
        label: WebsiteHelper.getLabel(website),
        label_group_slug: website.label_group_slug || null,
        loading: false 
      }),
    },
    {
      value: 'Settings',
      onClick: website => history.push({ pathname: viewWebsiteProfile(website.slug) }),
    },
    {
      value: 'View',
      onClick: website => window.open(`https://${website.default_domain}`, '_blank'),
    },
    {
      value: 'Change Plan',
      onClick: website => {
        // Set up the modal.
        const plans = PlanHelper.filterSubscribableWebsitePlans(partnerWebsitePlans, [], website.partner_slug);
        setModal({
          id: 'change-plan',
          website_slug: website.slug,
          new_plan_slug: null,
          loading: false,
          loadingPlans: true,
          options: ArrayHelper.buildSelectOptions(plans, 'name', 'slug'),
        });
        // Get the website's current plan.
        WebsiteService.getSubscriptions({ website_slug: website.slug, }).then(_plans => {
          // Get the available plans for the website's partner.
          const partnerPlanSlug = _plans.find(p => p.subscriber_type === 'partner')?.plan_slug;
          setModal(prev => ({ ...prev, old_plan_slug: partnerPlanSlug, loadingPlans: false, }));
          // Add the current plan to the list of options.
        }).catch(err => {
          window.logHelper.error('Failed to get website plans', err);
          setModal(prev => ({ ...prev, loadingPlans: false, }));
        });
      },
      doHide: () => !UserHelper.isAdminOrAgent() && !UserHelper.isPartner() && UserHelper.hasAccessToBetaFeatures(),
    },
    {
      value: 'Refresh',
      onClick: onRefreshWebsite,
    },
    {
      value: 'Delete',
      doHide: item => !UserHelper.hasPermissions(`website:delete:${item.slug}`),
      onClick: website => DialogHelper
        .confirmDelete(confirm, WebsiteHelper.getLabel(website), 'website')
        .then(() => handleDeleteWebsite(website)),
    },
  ];

  const getColumnWidth = selector => {
    return {
      website: '30%',
      state: '5%',
      status: '5%',
      cdns: '5%',
      site_cached_by: '5%',
      redis_status: '5%',
      domains_ssl: '5%',
      alert: '5%',
      is_live: '8%',
      wp_version: '8%',
      php_version: '8%',
      actions: '11%',
    }[selector];
  };

  // eslint-disable-next-line
  const customColumns = [
    {
      name: 'Website',
      selector: 'slug',
      sortable: true,
      searchable: true,
      width: getColumnWidth('website'),
      cell: row => {
        const adminSearch = UserHelper.isAdminOrAgent() ? `${row.region_slug} ${row.server_slug} ${row.partner_slug} ${row.database_name}` : '';
        const normalSearch = `${WebsiteHelper.getLabel(row)} ${row.default_domain} ${row.slug}`;
        row = TableHelper.customizeCellValue(row, 'slug', `${adminSearch} ${normalSearch}`);
        const labelGroup = UserHelper.isPartnerOrEmployee() ? WebsiteHelper.getLabelGroup(row, globalLabelGroups) : null;
        const labelGroupStyle = labelGroup ? { cssText: `color: ${labelGroup.color} !important`, marginRight: '5px', display: 'inline-block', position: 'relative', top: '-1.5px', fontWeight: 'bold' } : {};
        return (
          <div>
            <span className='cell-header class-link profile-link' onClick={() => openWebsiteProfile(row.slug)}>{WebsiteHelper.getLabel(row)}</span>
            {labelGroup && <span style={labelGroupStyle}>{`(${labelGroup.name})`}</span>}
            {WebsiteHelper.createOneClickLoginBubble(row, loadingWhileLogin, setLoadingWhileLogin, dispatch, modalDialog)}
            {WebsiteHelper.createLink(row, 'inactive-font-color cell-subheader padding-right-12 break-all')}
            {UserHelper.isAdminOrAgent() && <span className='cell-subheader' style={{ marginTop: '5px' }}>{row.server_slug} / {row.partner_slug}</span>}
          </div>
        );
      },
    },
    {
      name: 'State',
      selector: 'state',
      width: getColumnWidth('state'),
      cell: row => {
        const style = WebsiteHelper.getStateStyle(row.state);
        return JsxHelper.createIcon({
          color: style.color,
          icon: style.icon,
          tooltip: style.text,
          customClass: 'state-cell',
        })
      },
    },
    {
      name: 'Status',
      selector: 'status',
      width: getColumnWidth('status'),
      cell: row => {
        const style = WebsiteHelper.getStatusStyle(row.status, row.status_reason);
        return JsxHelper.createIcon({
          color: style.color,
          icon: style.icon,
          tooltip: style.text,
          customClass: 'status-cell',
        });
      },
    },
    {
      name: 'CDN',
      selector: 'cdns',
      width: getColumnWidth('cdns'),
      cell: row => {
        const cdn = WebsiteHelper.getCdnCache(row) || WebsiteHelper.getCdnProxy(row) || WebsiteHelper.getCloudflareZone(row);
        const status = WebsiteHelper.getCdnStatus(cdn);
        row = TableHelper.customizeCellValue(row, 'cdns', status.filter);
        return JsxHelper.createIcon({
          color: status.color,
          icon: status.icon,
          tooltip: status.text,
          customClass: `cdn-cell ${status.customClass}`,
        });
      },
    },
    {
      name: 'Cache',
      selector: 'site_cached_by',
      width: getColumnWidth('site_cached_by'),
      cell: row => {
        const cached = row.site_cached_by !== null;
        let tooltip = 'No cache';
        let color = 'inactive';
        if (cached) {
          tooltip = row.site_cached_by;
          color = 'success';
        }
        row = TableHelper.customizeCellValue(row, 'site_cached_by', tooltip);
        return JsxHelper.createIcon({
          color,
          icon: 'cache',
          tooltip,
          customClass: 'cache-cell',
        });
      },
    },
    {
      name: 'Redis',
      selector: 'objectcache_config',
      width: getColumnWidth('redis_status'),
      cell: row => {
        const config = row.objectcache_config;
        let tooltip = 'Not configured';
        let color = 'inactive';
        let status = 'Disabled';
        if (!isEmptyOrNull(config) && config.enabled === 'yes') {
          tooltip = `Enabled with max storage of ${config.max_storage}`
          status = 'Enabled';
          color = 'success';
        }
        TableHelper.customizeCellValue(row, 'objectcache_config', status);
        return JsxHelper.createIcon({
          color,
          icon: 'redis',
          tooltip,
          customClass: 'redis-cell',
        });
      },
    },
    {
      name: 'SSL',
      selector: 'domains_ssl',
      width: getColumnWidth('domains_ssl'),
      cell: row => {
        const details = WebsiteHelper.parseSSL(row.domains, row.domains_ssl);
        TableHelper.customizeCellValue(row, 'domains_ssl', details.tooltip);
        return JsxHelper.createIcon({
          color: details.modalParams.type,
          icon: details.icon,
          tooltip: details.tooltip,
          customClass: 'ssl-cell',
        });
      },
    },
    {
      name: 'Alerts',
      selector: 'alert',
      width: getColumnWidth('alert'),
      sortable: true,
      sortFunction: (a, b) => WebsiteHelper.getAlerts(a).rank - WebsiteHelper.getAlerts(b).rank,
      cell: row => {
        TableHelper.customizeCellValue(row, 'alert', WebsiteHelper.getAlertLevel(row));
        return WebsiteHelper.createAlertIcon(history, row, 'alert-cell');
      },
    },
    {
      name: 'Environment',
      selector: 'env',
      width: getColumnWidth('is_live'),
      sortable: true,
      cell: row => {
        const envName = WebsiteHelper.getEnvName(row);
        const envId = WebsiteHelper.getEnvId(row);
        TableHelper.customizeCellValue(row, 'env', envName);
        return JsxHelper.createIcon({
          icon: `${envId}Site`,
          tooltip: envName,
          customClass: `env-cell env-cell-${envId}`
        });
      },
    },
    JsxHelper.createTableTextHeaderWithCallback(
      'wp_version',
      'WP Version',
      getColumnWidth('wp_version'), 
      (row) => <span className='wp-version'>{row.wp_version}</span>,
      false
    ),
    JsxHelper.createTableTextHeaderWithCallback(
      'php_version',
      'PHP Version',
      getColumnWidth('php_version'), 
      (row) => <span className='php-version'>{row.php_version}</span>,
      false
    ),
    JsxHelper.createTableHiddenHeader('region_slug', 'Region'), // used for filter only
    UserHelper.isAdminOrAgent() ? JsxHelper.createTableHiddenHeader('partner_slug', 'Partner') : null,
    UserHelper.isAdminOrAgent() ? JsxHelper.createTableHiddenHeader('server_slug', 'Server') : null, // used for filter only
    JsxHelper.createTableHiddenHeader('label_group_slug', 'Label Group', (row) => WebsiteHelper.getLabelGroupName(row, globalLabelGroups)), // used for filter only
    JsxHelper.createTableActionsHeader(actions, getColumnWidth('actions')),
  ].filter(Boolean);

  // --------------------------------------------------
  // RENDER
  // --------------------------------------------------

  // Note: This is a workaround to prevent the table from re-rendering when the modal is being edited.
  const renderTable = useMemo(() => {
    return (
      <WPSDataTable name='websites' columns={customColumns} body={websites} loading={loading} preserveViewPreferences={true}/>
    );
    // eslint-disable-next-line
  }, [websites, loading]);

  return (
    <Container id="websites-table" style={{overflow: 'inherit' }}>
      {renderTable}
      {modal && modal.id === 'edit-label' && DialogHelper.inputs({
        title: 'Edit Website Label',
        icon: 'edit',
        iconColor: 'success',
        btnText: 'Edit',
        onConfirm: handleSubmit(handleEditLabel),
        onClose: () => setModal(false),
        loading: modal.loading,
        register,
        inputs: [{
          name: 'label',
          label: 'New Label',
          placeholder: `Enter new label for "${modal.website_slug}"`,
          value: modal.label,
          onChange: e => setModal({ ...modal, label: e.target.value }),
          errors,
          required: true,
          minLength: 5,
        }, {
          name: 'label_group_slug',
          label: 'Label Group',
          type: 'select',
          value: modal.label_group_slug || '-',
          required: false,
          errors,
          options: ArrayHelper.buildSelectOptions(globalLabelGroups, 'name', 'slug', 'None', '-'),
          onChange: e => setModal({ ...modal, label_group_slug: e.target.value }),
          after: isEmptyOrNull(globalLabelGroups)
            ? <span className='text-muted'>No label groups found. Create one in {JsxHelper.createClickableText('Settings', openWhiteLabelPage)}.</span>
            : null,
        }],
      })}
      {modal && modal.id === 'change-plan' && DialogHelper.inputs({
        title: 'Change Plan',
        icon: 'edit',
        iconColor: 'success',
        btnText: 'Change',
        onConfirm: handleSubmit(handleChangePlan),
        onClose: () => setModal(false),
        loading: modal.loading,
        header: modal.loadingPlans
          ? 'Loading plans...'
          : <div>
              <span className='display-block'>You are about to change the plan for <strong>{modal.website_slug}</strong>.</span>
              <span className='display-block'>For more information, please refer to the {JsxHelper.createClickableText('Plans', openPlansPage)} page.</span>
            </div>,
        inputs: !modal.loadingPlans ? [{
          name: 'old_plan_slug',
          label: 'Current Plan',
          value: modal.old_plan_slug,
          options: modal.options,
          type: 'select',
          disabled: true,
        }, {
          name: 'new_plan_slug',
          label: 'New Plan',
          type: 'select',
          value: modal.new_plan_slug,
          options: modal.options.filter(p => p.value !== modal.old_plan_slug),
          onChange: e => setModal({ ...modal, new_plan_slug: e.target.value }),
        }] : [],
      })}
    </Container>
  );
};

export default TableView;
