import { postRequest, getRequest, deleteRequest } from '../config/axiosRequest';
import env from '../config/env';

/**
 * List all users by the specified role.
 *
 * @param   {string}  role
 * @return  {Array}
 */
const list = data => {
  return getRequest({
    url: env.buildApiEndpoint('users'),
    data,
  });
};

/**
 * List children users by the specified role.
 *
 * @param   {string}  role
 * @return  {Array}
 */
const listChildren = data => {
  return getRequest({
    url: env.buildApiEndpoint(`users/${data.user_slug}/children`),
    data,
  });
};

/**
 * Create a new user with a specified role.
 *
 * @param   {string}  display_name
 * @param   {string}  email
 * @param   {string}  password
 * @param   {string}  role
 * @param   {string}  first_name
 * @param   {string}  last_name
 * @return  {Object}
 */
const create = data => {
  return postRequest({
    url: env.buildApiEndpoint('users'),
    data,
  });
};

/**
 * Update the specified user.
 *
 * @param   {Object}  data
 * @return  {Object}
 */
const update = data => {
  return postRequest({
    url: env.buildApiEndpoint(`users/${data.user_slug}`),
    data,
  });
};

/**
 * Update the specified user two factor status.
 *
 * @param   {Object}  data
 * @return  {Object}
 */
const updateTwoFactorStatus = data => {
  return postRequest({
    url: env.buildApiEndpoint(`users/${data.user_slug}/two-factor`),
    data,
  });
};

/**
 * Delete the specified user.
 *
 * @param   {userSlug}  userSlug
 */
const del = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`users/${data.user_slug}`),
    data,
  });
};

// Fetch website activity history
export const fetchUserActivites = data => {
  return getRequest({
    url: env.buildApiEndpoint(`users/${data.user_slug}/activity`),
    data,
  });
};

// Resend verification email.
const resendVerificationEmail = data => {
  return postRequest({
    url: env.buildApiEndpoint(`users/${data.user_slug}/resend-verification-email`),
    data,
  });
};

// Send a user's password reset email
const sendPasswordResetEmail = data => {
  return postRequest({
    url: env.buildApiEndpoint(`users/${data.user_slug}/send-password-reset-email`),
    data,
  });
};

// Send a user's confirm identity email
const sendConfirmIdentityEmail = data => {
  return postRequest({
    url: env.buildApiEndpoint(`users/${data.user_slug}/send-confirm-id-email`),
    data,
  });
};

// Reset user password.
const resetPassword = data => {
  return postRequest({
    url: env.buildApiEndpoint(`users/${data.user_slug}/reset-password`),
    data,
  });
};

// Update custom WP Admin login user.
const updateCustomWPAdminLoginUser = data => {
  return postRequest({
    url: env.buildApiEndpoint(`users/${data.partner_slug}/wp-admin-user`),
    data,
  });
};

// Suspend user account.
const suspend = data => {
  return postRequest({
    url: env.buildApiEndpoint(`users/${data.user_slug}/suspend`),
    data,
  });
};

// Unsuspend user account.
const unsuspend = data => {
  return postRequest({
    url: env.buildApiEndpoint(`users/${data.user_slug}/unsuspend`),
    data,
  });
};

// Unsuspend user account.
const changeEmail = data => {
  return postRequest({
    url: env.buildApiEndpoint(`users/${data.user_slug}/email`),
    data,
  });
};

const UserService = {
  list,
  listChildren,
  create,
  update,
  delete: del,
  suspend,
  unsuspend,
  resendVerificationEmail,
  sendPasswordResetEmail,
  sendConfirmIdentityEmail,
  resetPassword,
  updateCustomWPAdminLoginUser,
  updateTwoFactorStatus,
  changeEmail,
};

export default UserService;
