import React, { useState, useEffect } from 'react';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import GoogleAnalyticsService from 'services/google-analytics';
import { Container } from 'styles/website/profile';
import JsxHelper from 'helpers/jsx';
import { getErrorMsg, isEmptyOrNull } from 'helpers';
import DialogHelper from 'helpers/dialog';
import ArrayHelper from 'helpers/array';
import useModal from 'hooks/useModal';
import GoogleAnalyticsHelper from 'helpers/googleAnalytics';
import env from 'config/env';
import { useHistory } from 'react-router-dom';
import LocalStorageHelper from 'helpers/localStorage';

const GoogleAnalytics = ({ website }) =>{
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountProperties, setAccountProperties] = useState([]);
  const [property, setProperty] = useState([]);
  const [modal, setModal] = useState(false);
  const modalDialog = useModal();
  const history = useHistory();

  // ------------------------------
  // Lifecycle
  // ------------------------------

  useEffect(() => {
    getProperty();
    // eslint-disable-next-line
  }, []);

  // ------------------------------
  // API
  // ------------------------------

  const fetchAccounts = () => {
    const refreshInterval = 15 * 60; // 15 minutes (calls Google API every 15 minutes)
    const data = { website_slug: website.slug, refresh_interval: refreshInterval };
    setLoading('accounts');
    GoogleAnalyticsService.listAccounts(data)
      .then((accounts) => {
        setAccounts(accounts);
        if (isEmptyOrNull(accounts)) {
          openNoAccountsModal();
        }
      })
      .finally(() => setLoading(false));
  }

  const getProperty = () => {
    const data = { website_slug: website.slug };
    setLoading('property');
    GoogleAnalyticsService.getWebsiteProperty(data)
      .then((property) => {
        setProperty(property)
        setLoading(false);
        if (isEmptyOrNull(property)) {
          fetchAccounts(); // Fetch accounts if property is empty so user can connect
        }
      })
      .catch(() => setLoading(false));
  }

  const connectProperty = () => {
    const data = { website_slug: website.slug, account_id: modal.account_id, property_id: modal.property_id};
    setLoading('connect');
    GoogleAnalyticsService.connectWebsiteProperty(data)
      .then((property) => {
        setProperty(property);
        setModal(false);
      })
      .catch((error) => setModal({ ...modal, error: getErrorMsg(error) }))
      .finally(() => setLoading(false));
  }

  const disconnectProperty = () => {
    const data = { website_slug: website.slug, property_id: property.property_id };
    setLoading('disconnect');
    GoogleAnalyticsService.disconnectWebsiteProperty(data)
      .then(() => {
        setProperty(null);
        fetchAccounts();
      })
      .catch(() => setLoading(false));
  }

  // ------------------------------
  // Modal
  // ------------------------------

  const goToGlobalSettings = () => history.push({ pathname: `/settings/google-analytics` });
  const openNoAccountsModal = () => DialogHelper.info(
    modalDialog,
    '<b>No Google Analytics Accounts</b>',
    'You have not connected any Google Analytics accounts. Please click the button below to connect your account.',
    {
      btnText: 'Connect Account',
      btnOnClick: (onClose) => {
        onClose();
        // Save website slug to redirect back to this page after connecting account
        window.logHelper.info(`Saving website slug ${website.slug} to redirect back after connecting Google Analytics account`);
        LocalStorageHelper.write(GoogleAnalyticsHelper.REDIRECT_TO_CACHE_KEY, website.slug, 5);
        // Redirect to global settings page
        goToGlobalSettings();
      },
    }
  );

  const openConnectModal = () => {
    if (isEmptyOrNull(accounts)) {
      openNoAccountsModal();
      return;
    }
    const defaultAccount = accounts[0];
    setAccountProperties(defaultAccount.properties);
    setModal({
      name: 'connect-property',
      account_id: defaultAccount.account_id,
      property_id: null,
    });
  }

  // ------------------------------
  // Render
  // ------------------------------

  const renderConnectFragment = () => <div className='info-box'>
    <p><strong>Connect your Google Analytics account</strong> track website traffic and seamlessly integrate performance data into your monthly reports.</p>
    <div className="action-buttons">
      {JsxHelper.createButton({
        label: `Connect ${GoogleAnalyticsHelper.SERVICE_NAME}`,
        onClick: openConnectModal,
        loading: loading === 'accounts',
      })}
    </div>
  </div>

  const renderDisconnectFragment = () => <div className='info-box'>
    <h4>Connected Account</h4>
    <p><strong>Account:</strong> {property.account_email}</p>
    <p><strong>Property:</strong> {property.display_name}</p>
    <div className='action-buttons'>
      {JsxHelper.createButton({
        label: `Disconnect ${GoogleAnalyticsHelper.SERVICE_NAME}`,
        onClick: disconnectProperty,
        loading: loading === 'disconnect',
      })}
    </div>
  </div>

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Google Analytics</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
        {env.getBrandShortName()} makes it easy to connect <b>{JsxHelper.createLink('https://google.com/analytics', 'Google Analytics')}</b>, giving you valuable insights into website performance. This data is automatically included in your monthly reports to help you and your clients make informed decisions.
      </p>
      <Content>
        {loading !== 'property'
          ? (isEmptyOrNull(property) ? renderConnectFragment() : renderDisconnectFragment())
          : JsxHelper.createLoadingSpinner()
        }
      </Content>
      {modal && modal.name === 'connect-property' && DialogHelper.inputs({
        title: `Connect ${GoogleAnalyticsHelper.SERVICE_NAME} Account`,
        onClose: () => setModal(false),
        onConfirm: connectProperty,
        icon: 'googleAnalytics',
        iconColor: 'info',
        confirmBtn: 'Connect',
        confirmColor: 'success',
        loading: loading === 'connect',
        error: modal.error,
        inputs: [
          {
            type: 'select',
            name: 'account_id',
            label: 'Account',
            options: ArrayHelper.buildSelectOptions(accounts, 'email', 'account_id'),
            value: modal.account_id,
            required: true,
            onChange: e => {
              setModal({ ...modal, account_id: e.target.value });
              setAccountProperties(accounts.find(account => account.account_id === e.target.value).properties);
            },
          },
          {
            type: 'select',
            name: 'property_id',
            label: 'Property',
            options: ArrayHelper.buildSelectOptions(accountProperties, 'property_name', 'property_id'),
            value: modal.property_id,
            required: true,
            onChange: e => setModal({ ...modal, property_id: e.target.value }),
          },
        ],
      })}
    </Container>
  );
};

export default GoogleAnalytics;
