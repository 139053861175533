import React from 'react';
import { WPSInput, WPSLabel, ErrorMsg } from 'styles/layout/forms';
import JsonInspector from 'react-json-inspector';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import { isNullOrUndefined, isString, isUndefined, getErrorMsg } from 'helpers';
import Modal from 'components/layout/modal';
import StringHelper from './string';
import FormHelper from './form';
import Tooltip from 'components/layout/tooltip';
import JsxHelper from './jsx';

// Helper functions

const _renderCheckbox = (input, index) =>
  JsxHelper.createCheckbox(input, index);

const _renderTextInput = (input, index) =>
  JsxHelper.createTextInput(input, index);

const _renderTextarea = (input, index) => 
  JsxHelper.createTextareaInput(input, index);

const _guessIconFromText = (text) => {
  if (!text) {
    return false;
  }
  text = text.toLowerCase();
  if (['yes', 'ok', 'confirm', 'continue'].includes(text)) {
    return { icon: 'info', iconColor: 'info' };
  } else if (['no', 'cancel', 'close', 'missing'].includes(text)) {
    return { icon: 'warning', iconColor: 'warning' };
  } else if (['delete', 'trash', 'revoke', 'stop'].includes(text)) {
    const icon = text === 'stop' ? 'stop' : 'trash';
    return { icon, iconColor: 'danger' };
  } else if (['assign', 'create', 'edit', 'save', 'add', 'update', 'change', 'rename'].includes(text)) {
    return { icon: 'create', iconColor: 'success' };
  }
  return false;
}

// Dialogs

const raw = (modalDialog, line1, params) => {
  params = params || {};
  params.line1 = line1 ? line1 : params.line1;
  params.btnText = params.btnText || 'Close';
  params.btnsStyle = 'standard';
  return modalDialog(params);
}

const loading = (modalDialog, line1, line2, params) => {
  params = params || {};
  params.line1 = line1 ? line1 : params.line1;
  params.line2 = line2 ? line2 : params.line2;
  params.type = 'loading';
  params.iconSpinner = true;
  return raw(modalDialog, line1, params);
}

const success = (modalDialog, line1, line2, params) => {
  params = params || {};
  params.line1 = line1 ? line1 : params.line1;
  params.line2 = line2 ? line2 : params.line2;
  params.type = 'success';
  return raw(modalDialog, line1, params);
}

const warning = (modalDialog, line1, line2, params) => {
  params = params || {};
  params.line1 = line1 ? line1 : params.line1;
  params.line2 = line2 ? line2 : params.line2;
  params.type = 'warning';
  params.icon = 'warning';
  params.iconColor = 'warning';
  return raw(modalDialog, line1, params);
}

const error = (modalDialog, line1, line2, params) => {
  params = params || {};
  if (!isString(line1)) {
    line1 = getErrorMsg(line1);
  }
  params.line1 = line1 ? line1 : params.line1;
  params.line2 = line2 ? line2 : params.line2;
  params.type = 'danger';
  params.icon = 'danger';
  params.iconColor = 'danger';
  return raw(modalDialog, line1, params);
}

const info = (modalDialog, line1, line2, params) => {
  params = params || {};
  params.line1 = line1 ? line1 : params.line1;
  params.line2 = line2 ? line2 : params.line2;
  return raw(modalDialog, line1, params);
}

const plaintext = (modalDialog, text) => {
  return raw(modalDialog, text, {
    preventLine1Parsing: true,
    hideIcon: true,
    btnText: 'Close',
    modalCloseIcon: false,
  });
}

const popup = (modalDialog, btnText, callback, line1, line2, params, type) => {
  params = params || {};
  params.btnOnClick = (onClose) => {
    callback && callback();
    onClose();
  };
  params.catchOnCancel = true;
  params.btnText = btnText;
  params.closeBtn = isString(params.closeBtn) ? params.closeBtn : (params.closeBtn !== false ? 'Close' : false);
  params.modalCloseIcon = params.modalCloseIcon || false;
  params.btnsAlign = params.btnsAlign || 'flex-center'
  switch (type) {
    case 'success':
      return success(modalDialog, line1, line2, params);
    case 'warning':
      return warning(modalDialog, line1, line2, params);
    case 'error':
      return error(modalDialog, line1, line2, params);
    default:
      return info(modalDialog, line1, line2, params);
  }
}

const html = (modalDialog, html, props) =>
  modalDialog({ rawHtml: html, hideHeader: true, ...props });

const knowledgeBasePopup = (modalDialog, page, subject, type) => {
  const href = JsxHelper.createFaqLink(page, 'knowledge base');
  const content = `For more information, please refer to our ${JsxHelper.convertToString(href)}.`;
  subject = subject || 'Oops! Something went wrong.';
  type = type || 'error';
  if (type === 'error') {
    return error(modalDialog, subject, content);
  } else if (type === 'warning') {
    return warning(modalDialog, subject, content);
  }
  return info(modalDialog, subject, content);
}

const confirmAction = (confirmDialog, action, resourceId, resourceType, description, noKeyword, btnText, icon, iconColor) => {
  resourceType = resourceType || 'resource';
  action = action.toLowerCase();
  const iconData = _guessIconFromText(action);
  iconColor = iconColor || (iconData ? iconData.iconColor : 'warning');
  icon = icon || (iconData ? iconData.icon : 'warning');
  let question = <span>Are you sure you want to continue?</span>;
  if (resourceId) {
    // If the type has more than 1 capital letter, then don't lowercase it.
    const _type = (resourceType.match(/[A-Z]/g) || []).length < 2 ? resourceType.toLowerCase() : resourceType;
    const article = icon === 'create' ? (['a', 'e', 'i', 'o', 'u'].includes(_type[0]) ? 'an' : 'a') : 'the';
    question = <span>Are you sure you want to {action} {article} <b>{resourceId}</b> {_type}?</span>
  }
  let descClasses = 'confirm-action-info';
  let descPrefix = null;
  if (description && isString(description) && description.toLowerCase().startsWith('warning')) {
    description = description.replace(/^warning\s*:\s*/i, '');
    descPrefix = <b>WARNING:{' '}</b>;
    descClasses += ' warning-box margin-bottom-30';
  }
  return confirmDialog({
    icon,
    iconColor,
    title: StringHelper.capitalizeFirstLetter(`${action} ${resourceType}`, true),
    header: <div className='confirm-action-header'>
      {description && <div className={descClasses}>{descPrefix}{description}</div>}
      <div className='confirm-action-question'>{question}</div>
    </div>,
    keyword: noKeyword ? false : action,
    confirmColor: `${iconColor}--btn`,
    confirmBtn: btnText || StringHelper.toText(action),
  });
}

const confirmRemove = (confirmDialog, resourceId, resourceType) => {
  return confirmAction(confirmDialog, 'remove', resourceId, resourceType);
}

const confirmDelete = (confirmDialog, resourceId, resourceType) => {
  return confirmAction(confirmDialog, 'delete', resourceId, resourceType);
}

const jsonViewer = (_data) => {
  const { onClose, data } = _data;
  return <Modal onClose={onClose} maxWidth='500px' closeBtn='Close'>
    <JsonInspector className='json-viewer' data={data} />
  </Modal>
}

const alert = (message) => {
  window.alert(message);
}

const inputs = (data) => {
  let { title, className, onClose, onConfirm, confirmColor, confirmBtn, btnText, loading, inputs, header, btnColor, icon, iconColor, disabled, register, error } = data;
  inputs = (inputs || []).filter(Boolean).map(input => {
    input.type = input.type || 'text';
    input.value = isUndefined(input.value) ? '' : input.value;
    if (input.type !== 'checkbox') {
      // If a label is provided, store it in a separate field and remove it from the input
      // to avoid rendering it twice (prevent _render* functions from rendering a label).
      input._label = input.label || '';
      delete input.label;
    }
    if (register && !input.ref) {
      // Fill up the register with the input's validation.
      if (!input.name) {
        window.logHelper.warning(`Input ${input.label} is missing a name!`, input);
      } else {
        const validation = {};
        if (input.required) {
          validation.required = FormHelper.messages.required;
        }
        if (input.minLength) {
          validation.minLength = {
            value: input.minLength,
            message: FormHelper.messages.minLength(input.minLength),
          };
        }
        if (input.type === 'number') {
          if (!isNullOrUndefined(input.min)) {
            validation.min = {
              value: input.min,
              message: FormHelper.messages.minNumber(input.min),
            };
          }
          if (!isNullOrUndefined(input.max)) {
            validation.max = {
              value: input.max,
              message: FormHelper.messages.maxNumber(input.max),
            };
          }
        }
        input.ref = register(validation);
      }
      // If input has no errors field, issue a warning.
      if (!input.hasOwnProperty('errors')) {
        window.logHelper.warning(`Input ${input.label} is missing an errors field!`, input);
      }
    }
    return input;
  })
  confirmBtn = confirmBtn || btnText;
  confirmColor = confirmColor || btnColor;
  if (!iconColor) {
    const iconData = _guessIconFromText(confirmBtn);
    iconColor = iconData ? iconData.iconColor : 'info';
    icon = iconData ? iconData.icon : 'info';
  }
  if (confirmColor && !confirmColor.includes('--btn')) {
    confirmColor = `${confirmColor}--btn`;
  }
  return <Modal
    className={`${className||''} input-modal`}
    icon={icon}
    disabled={disabled}
    iconColor={iconColor}
    title={title}
    onClose={onClose}
    onConfirm={(e) => onConfirm(e, inputs)}
    confirmBtn={confirmBtn}
    confirmColor={confirmColor} 
    loading={loading}>
    {header && <div className='input-modal-header'>{header}</div>}
    {error && JsxHelper.createErrorBox(error)}
    {inputs.map((input, index) => <div key={index} style={{marginTop: '18px'}} className={input.class||''}>
      {input._label && input.type !== 'checkbox' && <WPSLabel className='input-modal-label'>
        {input._label}:
        {input.required && <i className='required'>*</i>}
        {input.errors && input.errors[input.name] && <ErrorMsg>{input.errors[input.name].message}</ErrorMsg>}
        {input.labelTooltip && <Tooltip text={input.labelTooltip} place='right' />}
        {input.labelCustom ? input.labelCustom : null}
      </WPSLabel>}
      {['text', 'password'].includes(input.type) && _renderTextInput(input, index)}
      {input.type === 'textarea' && _renderTextarea(input, index)}
      {input.type === 'checkbox' && _renderCheckbox(input, index)}
      {input.type === 'select' && <WPSSelect
        key={index || StringHelper.randomString()}
        selectClass={input.selectClass || ''}
        name={input.name}
        value={input.value}
        placeholder={input.placeholder}
        options={input.options}
        isMultiSelect={input.isMultiSelect || false}
        closeMenuOnSelect={input.closeMenuOnSelect || true}
        onChange={input.onChange}
        isSearchable={input.isSearchable || true}
        disabled={input.disabled || false}
        sortOff={input.sortOff || true}
        refs={input.ref || null} // https://stackoverflow.com/questions/56484686/how-do-i-avoid-function-components-cannot-be-given-refs-when-using-react-route
      />}
      {input.type === 'color' && JsxHelper.createColorInput(input)}
      {input.type === 'number' && <WPSInput
        key={index}
        name={input.name}
        type='number'
        value={input.value}
        onChange={input.onChange}
        disabled={input.disabled || false}
        min={input.min || 0}
        max={input.max || null}
        ref={input.ref || null}
      />}
      {['datetime-local', 'datetime', 'date'].includes(input.type) && <WPSInput
        style={{ height: 'auto' }}
        type={input.type}
        name={input.name}
        min={input.min || null}
        max={input.max || null}
        value={input.value}
        selected={input.selected}
        placeholder={input.placeholder || ''}
        onChange={input.onChange}
        disabled={input.disabled || false}
        ref={input.ref || null}
      />}
      {input.after && <div style={input.afterStyle||{}} className='input-modal-after'>{input.after}</div>}
    </div>)}
  </Modal>;
}

const DialogHelper = {
  success,
  warning,
  error,
  info,
  loading,
  popup,
  alert,
  html,
  knowledgeBasePopup,
  plaintext,
  inputs,
  jsonViewer,
  confirmAction,
  confirmDelete,
  confirmRemove,
};

export default DialogHelper;
