import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Content } from 'styles/globalStyles';
import { TitleBar, GroupTitle } from 'styles/layout/titlebar';
import { websitesSelector, websitesListSelector } from 'store/website/websiteSelectors';
import ClientReportService from 'services/clientReport';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import StringHelper from 'helpers/string';
import JsxHelper from 'helpers/jsx';
import { WPSForm } from 'styles/layout/forms';
import DialogHelper from 'helpers/dialog';
import DateHelper from 'helpers/date';
import { useForm } from 'react-hook-form';
import useModal from 'hooks/useModal';
import useConfirm from 'hooks/useConfirm';
import { setGlobalSuccessMsg, setGlobalPleaseWaitMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import WebsiteHelper from 'helpers/website';
import { useDispatch } from 'react-redux';
import { timezoneSelector } from 'store/me/meSelectors';
import ArrayHelper from 'helpers/array';
import { globalClientReportConfigSelector } from 'store/setting/settingSelectors';
import UserHelper from 'helpers/user';
import TableHelper from 'helpers/table';
import { getErrorMsg, isEmptyOrNull } from 'helpers';
import { partnerBillingDetails } from 'store/billing/billingSelectors';
import { useHistory } from 'react-router-dom';
import useTitle from 'hooks/useTitle';

const ClientReports = () => {
  useTitle('Client PDF Reports');
  const SCREEN_CLIENT_REPORTS = 'Client Reports';
  const SCREEN_CLIENT_LIST = 'Client List';
  const SCREEN_SETTINGS = 'Settings';
  const SETTINGS_MESSAGE_CONSTANTS = `You can use the following placeholders in the custom messages:<br>${[
    { placeholder: 'client_name', tooltip: 'The name of the client as defined in the Client List.' },
    { placeholder: 'business_name', tooltip: 'Your business name as defined in Billing > Details.' },
    { placeholder: 'report_period', tooltip: 'The period of the report.' },
  ].map(o => `{{${o.placeholder}}}: ${o.tooltip}`).join('<br>')}`;
  const BREADCRUMBS = JsxHelper.createBreadcrumbs('Client Reports', 'reports');
  const SETTINGS_TABS = [
    { title: 'General Settings', value: 'general' },
    { title: 'Styles', value: 'styles' },
    { title: 'Letter Content', value: 'messaging' },
  ].filter(Boolean);
  const [currentTab, setCurrentTab] = useState(SETTINGS_TABS[0].value);
  const { handleSubmit, register, errors } = useForm({ reValidateMode: 'onSubmit' });
  const [loadingPDFs, setLoadingPDFs] = useState(false);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [loadingReceivers, setLoadingReceivers] = useState([false]);
  const modalDialog = useModal();
  const [disableLoadMore, setDisableLoadMore] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [receiversData, setReceiversData] = useState([]);
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(1);
  const [currentScreen, setCurrentScreen] = useState(SCREEN_CLIENT_REPORTS);
  const history = useHistory();
  const confirm = useConfirm();
  const timezone = useSelector(timezoneSelector);
  const allWebsites = useSelector(websitesSelector);
  const reportConfig = useSelector(globalClientReportConfigSelector);
  const billingDetails = useSelector(partnerBillingDetails);
  const showCustomSMTPTip = UserHelper.isPartner() && !UserHelper.hasIntegration('custom_smtp');
  const hasUpdatePermissions = UserHelper.isPartner() || UserHelper.hasPermissions(`client-pdf-report:update:*`);
  const hasDeletePermissions = UserHelper.isPartner() || UserHelper.hasPermissions(`client-pdf-report:delete:*`);
  const websitesSelectOptions = useSelector(websitesListSelector).filter(option => {
    const website = allWebsites.find(w => w.slug === option.value);
    return website && website.is_live;
  });
  const mounted = useRef(true)
  const PAGE_LIMIT = 100;
  const EXCLUDE_PAGE_OPTIONS = [
    { label: 'Cover Page', value: 'cover' },
    { label: 'Table of Contents', value: 'table_of_contents' },
    { label: 'Website Summary', value: 'summary' },
    { label: 'Active Plugins', value: 'active_plugins' },
    { label: 'Website Analytics', value: 'analytics' },
    { label: 'Plugin & Theme Updates', value: 'updates' },
    { label: 'Firewall', value: 'firewall' },
    { label: 'Backups', value: 'backups' },
    { label: 'Google Analytics', value: 'google_analytics' },
    { label: 'WooCommerce', value: 'woocommerce' },
    { label: 'Closing Page', value: 'closing' },
  ];
  const SUPPORTED_FONTS = [
    'Poppins',
    'Roboto',
    'Montserrat',
  ].map(f => ({ label: f, value: f }));
  const DEFAULT_STYLES = {
    primary_color: '#004d4d',
    secondary_color: '#ffcc00',
    text_color: '#065454',
    font_family: 'Poppins',
  }
  const [settingsInput, setSettingsInput] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch partner settings.
    if (UserHelper.isPartnerOrEmployee()) {
      fetchPartnerSettings();
    }
    // Fetch all PDF reports.
    fetchReports();
    // Ask to complete billing details.
    maybeAskToCompleteBillingDetails();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const maybeAskToCompleteBillingDetails = () => {
    if (UserHelper.isPartner() && (!billingDetails || !billingDetails.logo_url)) {
      DialogHelper.popup(
        modalDialog,
        'Fill Details',
        () => history.push({ pathname: '/billing/details', }),
        'Oops! client reports are missing your details.',
        'Please complete filling your details so client reports can include your logo and contact information.',
        { closeBtn: false, modalCloseIcon: true },
        'warning'
      );
    }
  }

  // ------------------------------
  // Fetch Data
  // ------------------------------

  const fetchReceivers = () => {
    // Fetch all clients (only once).
    if (isEmptyOrNull(receiversData)) {
      const data = {}
      setLoadingReceivers(true);
      ClientReportService.listReceivers(data)
        .then((res) => setReceiversData(res))
        .catch((err) => dispatch(setGlobalErrorMsg(err)))
        .finally(() => setLoadingReceivers(false));
    }
  }

  const fetchReports = () => {
    const data = {
      page,
      limit: PAGE_LIMIT,
    }
    setLoadingPDFs(true);
    ClientReportService.listPDFs(data)
      .then((res) => {
        if (res.length < PAGE_LIMIT) {
          setDisableLoadMore(true);
        }
        setPage(page + 1);
        setReportsData(res);
      })
      .catch((err) => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoadingPDFs(false));
  }

  const fetchPartnerSettings = () =>  {
    setLoadingSettings(true);
    ClientReportService.getPartnerConfig()
      .then((settings) => {
        if (mounted.current) {
          window.logHelper.info('Fetched partner settings', settings);
          setSettingsInput(settings);
        }
      })
      .catch((err) => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoadingSettings(false));
  }

  const savePartnerSettings = () => {
    setLoadingSettings(true);
    ClientReportService.updatePartnerConfig({ config: settingsInput })
      .then((settings) => {
        if (mounted.current) {
          window.logHelper.info('Saved partner settings', settings);
          setSettingsInput(settings);
        }
        DialogHelper.success(modalDialog, 'Settings saved successfully.');
      })
      .catch((err) => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoadingSettings(false));
  }
  
  // ------------------------------
  // UI Handlers
  // ------------------------------

  const switchScreen = (screen) => {
    if (screen === SCREEN_CLIENT_LIST) {
      fetchReceivers();
    }
    setCurrentScreen(screen);
  }

  const goToClientSMTPSettings = () => history.push({ pathname: `/clients/custom-smtp` });

  const prepareEmails = (emails) => 
    ArrayHelper.unique(emails.split(',').map(e => e.trim().toLowerCase()).filter(Boolean));
  
  const onClickCreateClient = () => setModal({
    name: 'upsert-client',
    emails: '',
    website_slug: '',
  })

  const onClickCreatePreview = () => setModal({
    name: 'create-preview',
    website_slug: '',
    start_date: DateHelper.now().subtract(5, 'day').format('YYYY-MM-DD'),
    end_date: DateHelper.now().subtract(1, 'day').format('YYYY-MM-DD'),
  })

  const onClickSendEmail = (item) => setModal({
    name: 'send-email',
    client_name: '',
    client_emails: '',
    guid: item.guid,
  })

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setModal({ ...modal, [name]: value })
  }

  const handleAddClientSubmit = () => {
    setModal(prev => ({ ...prev, loading: true }));
    const data = {
      website_slug: modal.website_slug,
      name: modal.client_name,
      emails: prepareEmails(modal.client_emails),
      guid: modal.guid || null,
    }
    const apiFunc = modal.guid ? ClientReportService.updateReceiver : ClientReportService.createReceiver;
    apiFunc(data)
      .then((res) => {
        if (modal.guid) {
          setReceiversData(prev => prev.map(r => r.guid === res.guid ? res : r));
        } else {
          setReceiversData(prev => [res, ...prev]);
        }
        setModal(false);
        const website = allWebsites.find(w => w.slug === modal.website_slug);
        dispatch(setGlobalSuccessMsg({ model: 'client', action: 'saved', forId: website ? website.slug : '' }));
      })
      .catch((err) => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setModal(prev => ({ ...prev, loading: false })));
  }
  
  const handleSendEmailSubmit = () => {
    setModal(prev => ({ ...prev, loading: true }));
    const data = {
      emails: prepareEmails(modal.client_emails),
      guid: modal.guid,
    }
    ClientReportService.sendPDF(data)
      .then(() => {
        setModal(false);
        dispatch(setGlobalSuccessMsg({ model: 'report', action: 'sent' }));
      })
      .catch((err) => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setModal(prev => ({ ...prev, loading: false })));
  }

  const handleCreatePreviewSubmit = () => {
    // Verify website exists.
    if (!modal.website_slug) {
      DialogHelper.error(modalDialog, 'Please select a website.');
      return;
    }
    const website = allWebsites.find(w => w.slug === modal.website_slug);
    if (!website) {
      DialogHelper.error(modalDialog, 'Website not found.');
      return;
    }
    // Verify the start date is not more than one month ago.
    if (DateHelper.now().subtract(33, 'day').isAfter(modal.start_date)) {
      DialogHelper.error(modalDialog, 'Start date cannot be more than one month ago.');
      return;
    }
    // Verify the end date is not in the future.
    if (DateHelper.now().isBefore(modal.end_date)) {
      DialogHelper.error(modalDialog, 'End date cannot be in the future.');
      return;
    }
    // Create report asyncronously.
    const label = WebsiteHelper.getLabel(website);
    dispatch(setGlobalPleaseWaitMsg({ model: 'report', action: 'created', forId: label }));
    const data = {
      website_slug: modal.website_slug,
      start_date: modal.start_date,
      end_date: modal.end_date,
      type: 'preview',
    }
    setModal(false);
    ClientReportService.createPDF(data)
      .then((res) => {
        setReportsData(prev => [res, ...prev])
        dispatch(setGlobalSuccessMsg({ model: 'report', action: 'created', forId: label }));
      }).catch((err) => {
        DialogHelper.error(modalDialog, getErrorMsg(err));
        dispatch(setGlobalErrorMsg(err));
      });
  }

  const downloadPDF = (item) => {
    const data = {
      website_slug: item.website_slug,
      guid: item.guid,
    };
    ClientReportService.downloadPDF(data);
  }

  // Clients Table

  const clientsActions = [
    {
      value: 'Update',
      doHide: () => !hasUpdatePermissions,
      onClick: receiver => {
        setModal({
          name: 'upsert-client',
          website_slug: receiver.website_slug,
          client_name: receiver.name,
          client_emails: receiver.emails.join(', '),
          guid: receiver.guid,
        });
      }
    },
    {
      value: 'Delete',
      doHide: () => !hasDeletePermissions,
      onClick: receiver => {
        const website = allWebsites.find(w => w.slug === receiver.website_slug);
        DialogHelper.confirmDelete(confirm, receiver.name, 'client')
          .then(() => {
            dispatch(setGlobalPleaseWaitMsg({ model: 'client', action: 'deleted', forId: website ? website.slug : '' }));
            ClientReportService.deleteReceiver({ website_slug: receiver.website_slug, guid: receiver.guid})
          }).then(() => {
            setReceiversData(prev => prev.filter(r => r.guid !== receiver.guid));
            dispatch(setGlobalSuccessMsg({ model: 'client', action: 'deleted', forId: website ? website.slug : '' }));
          });
      }
    }
  ];

  const receiversHeader = [
    JsxHelper.createTableTextHeaderWithCallback('name', 'Client', '25%', (row) => {
      TableHelper.customizeCellValue(row, 'name', row.name + ' ' + row.emails.join(' '));
      return JsxHelper.createTableMultiLineCell({
        header: row.name,
        prettify: false,
        subheader: row.emails.join(', '),
        subheaderClass: 'text-word-break',
        style: { paddingRight: '5px' }
      })
    }),
    {
      name: 'Website',
      selector: 'website_slug',
      width: '30%',
      sortable: true,
      searchable: true,
      cell: (row) => {
        const website = allWebsites.find(w => w.slug === row.website_slug);
        if (website) {
          TableHelper.customizeCellValue(row, 'website_slug', WebsiteHelper.getLabel(website) + ' ' + website.default_domain);
        }
        return JsxHelper.createTableWebsiteCell({ website })
      },
    },
    JsxHelper.createTableBinaryBubbleHeader(
      'enabled',
      'Status',
      '15%',
      // If the current user is an admin or agent, don't check global settings.
      (row) => row.is_active && (reportConfig.enabled || UserHelper.isAdminOrAgent()),
      true,
      'Enabled',
      'Disabled'
    ),
    JsxHelper.createTableTimeHeader('last_sent_at', timezone, '15%', 'Last Emailed', 'Never'),
    JsxHelper.createTableActionsHeader(clientsActions, '15%'),
  ];

  // Reports Table

  const reportsActions = [{
    value: 'Send Email',
    onClick: onClickSendEmail,
  }, {
    value: 'Download',
    onClick: downloadPDF,
  }, {
    value: 'Delete',
    onClick: item => {
      const website = allWebsites.find(w => w.slug === item.website_slug);
      DialogHelper.confirmDelete(confirm, WebsiteHelper.getLabel(website), 'report')
        .then(() => {
          dispatch(setGlobalPleaseWaitMsg({ model: 'report', action: 'deleted', forId: website ? website.slug : '' }));
          ClientReportService.deletePDF({ website_slug: item.website_slug, guid: item.guid});
        }).then(() => {
          setReportsData(prev => prev.filter(r => r.guid !== item.guid));
          dispatch(setGlobalSuccessMsg({ model: 'report', action: 'deleted', forId: website ? website.slug : '' }));
        });
    }
  }]

  const reportsHeader = [
    JsxHelper.createTableTimeHeader('created_at', timezone, '13%', 'Create Time'),
    JsxHelper.createTablePeriodHeader('start_date', 'end_date', 'Report Period', '20%'),
    {
      name: 'Website',
      selector: 'website_slug',
      sortable: true,
      searchable: true,
      width: '28%',
      cell: (row) => JsxHelper.createTableWebsiteCell({
        website: allWebsites.find(w => w.slug === row.website_slug),
        paddingRight: '10px',
      }),
    },
    {
      name: <span>Type (<span style={{fontSize: '12px'}}>click to download</span>)</span>,
      selector: 'items',
      width: '27%',
      cell: row => JsxHelper.createButton({
        label: StringHelper.toText(row.type),
        classes: row.type === 'preview' ? 'primary--btn' : 'success--btn',
        small: true,
        onClick: () => downloadPDF(row),
      })
    },
    JsxHelper.createTableActionsHeader(reportsActions, '12%'),
  ];

  // Global Settings

  const renderGlobalSettingsTabs = () => 
    <GroupTitle>
      <GroupTitle.Filters>
        {SETTINGS_TABS.filter(tab => !tab.disabled).map(tab => (
          <button
            key={tab.value}
            className={currentTab === tab.value ? 'active' : ''}
            onClick={() => setCurrentTab(tab.value)}>
            {tab.title}
          </button>
        ))}
      </GroupTitle.Filters>
    </GroupTitle>

  const renderSettings = () =>
    <div style={{ maxWidth: currentTab === 'general' ? '500px' : '825px', marginBottom: '125px' }}>
      <WPSForm>
        {currentTab === 'general' && renderSettingsGeneral()}
        {currentTab === 'messaging' && renderLettersContent()}
        {currentTab === 'styles' && renderStyles()}
      </WPSForm>
    </div>

  const renderSettingsGeneral = () =>
    <Fragment>
      <WPSForm.RowItem>
        {JsxHelper.createSelectInput({
          label: 'Enable Monthly Reports',
          name: 'enabled',
          value: settingsInput.enabled,
          options: [{ label: 'Enabled', value: true }, { label: 'Disabled', value: false }],
          onChange: (e) => setSettingsInput({ ...settingsInput, enabled: e.target.value }),
        })}
      </WPSForm.RowItem>
      {<WPSForm.RowItem className='margin-left-0'>
        {JsxHelper.createNumberInput({
          label: 'Select Days to Send Reports (1-3)',
          name: 'send_emails_day',
          value: settingsInput.send_emails_day,
          disabled: !settingsInput.enabled,
          onChange: (e) => {
            let value = parseInt(e.target.value);
            value = (isNaN(value) || value < 1) ? 1 : (value > 3) ? 3 : value;
            setSettingsInput({ ...settingsInput, send_emails_day: value });
          },
          min: 1,
          max: 3,
        })}
      </WPSForm.RowItem>}
      {<WPSForm.RowItem className='margin-left-0'>
        {JsxHelper.createSelectInput({
          label: 'Pages to Exclude from Reports',
          name: 'exclude_pages',
          disabled: !settingsInput.enabled,
          value: settingsInput.exclude_pages,
          options: ArrayHelper.buildSelectOptions(EXCLUDE_PAGE_OPTIONS, 'label', 'value'),
          onChange: (e) => {
            const values = e.target.values.map(o => o.value);
            setSettingsInput({ ...settingsInput, exclude_pages: values });
          },
          multiSelect: true,
        })}
      </WPSForm.RowItem>}
    </Fragment>

const renderLettersContent = () => 
  <Fragment>
    {<WPSForm.Row><WPSForm.RowItem className='margin-left-0'>
      {JsxHelper.createTinyMCEInput({
        label: 'Custom Opening Message',
        name: 'opening_message',
        value: settingsInput.opening_message_html,
        onChange: (value) => setSettingsInput({ ...settingsInput, opening_message_html: value }),
        tooltip: `This message will be displayed on the cover page of the report. <br><br>${SETTINGS_MESSAGE_CONSTANTS}`,
      })}
    </WPSForm.RowItem></WPSForm.Row>}
    {<WPSForm.Row><WPSForm.RowItem className='margin-left-0 margin-top-24'>
      {JsxHelper.createTinyMCEInput({
        label: 'Custom Closing Message',
        name: 'closing_message',
        value: settingsInput.closing_message_html,
        onChange: (value) => setSettingsInput({ ...settingsInput, closing_message_html: value }),
        tooltip: `This message will be displayed on the closing page of the report. <br><br>${SETTINGS_MESSAGE_CONSTANTS}`,
      })}
    </WPSForm.RowItem></WPSForm.Row>}
  </Fragment>

  const renderStyles = () => 
    <Fragment>
      <WPSForm.Row style={{marginTop: '24px', }}>
        <WPSForm.RowItem style={{marginRight: '25px'}}>
          {JsxHelper.createColorInput({
            label: 'Brand Primary Color',
            name: 'primary_color',
            color: settingsInput.primary_color,
            onChange: (color) => setSettingsInput({ ...settingsInput, primary_color: color }),
            tooltip: 'This color is the primary brand color and will be the dominant color in the top bar, titles, and table headers.',
          })}
        </WPSForm.RowItem>
        <WPSForm.RowItem style={{marginRight: '25px'}}>
          {JsxHelper.createColorInput({
            label: 'Brand Secondary Color',
            name: 'secondary_color',
            color: settingsInput.secondary_color,
            onChange: (color) => setSettingsInput({ ...settingsInput, secondary_color: color }),
            tooltip: 'This color is the secondary brand color and will be the less dominant color in the top bar and mainly used in the footer.',
          })}
        </WPSForm.RowItem>
        <WPSForm.RowItem style={{marginRight: '25px'}}>
          {JsxHelper.createColorInput({
            label: 'Font Color',
            name: 'text_color',
            color: settingsInput.text_color,
            onChange: (color) => setSettingsInput({ ...settingsInput, text_color: color }),
            tooltip: 'This color will be used for all the text in the report.',
          })}
        </WPSForm.RowItem>
      </WPSForm.Row>
      <WPSForm.Row style={{marginTop: '24px', maxWidth: '50%'}}>
        <WPSForm.RowItem style={{marginRight: '25px'}}>
          {JsxHelper.createSelectInput({
            label: 'Font Family',
            name: 'font_family',
            value: settingsInput.font_family || SUPPORTED_FONTS[0].value,
            options: ArrayHelper.buildSelectOptions(SUPPORTED_FONTS, 'label', 'value'),
            onChange: (e) => setSettingsInput({ ...settingsInput, font_family: e.target.value }),
            tooltip: 'This font will be used for all the text in the report.',
          })}
        </WPSForm.RowItem>
      </WPSForm.Row>
      <WPSForm.Row style={{marginTop: '24px', marginBottom: '100px' }}>
          {JsxHelper.createButton({
            classes: 'warning--btn',
            onClick: () => setSettingsInput(prev => ({ ...prev, ...DEFAULT_STYLES })),
            label: 'Reset to Default',
          })}
      </WPSForm.Row>
    </Fragment>

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={BREADCRUMBS}>{currentScreen}</TitleBar.Title>
        <TitleBar.Actions>
          {UserHelper.isPartnerOrEmployee() && hasUpdatePermissions && currentScreen === SCREEN_CLIENT_REPORTS ? JsxHelper.createButton({ label: 'Settings', classes: 'primary--btn', loading: loadingSettings, onClick: () => switchScreen(SCREEN_SETTINGS) }) : null}
          {currentScreen === SCREEN_SETTINGS && JsxHelper.createButton({ label: 'Save Settings', classes: 'success--btn', onClick: savePartnerSettings, loading: loadingSettings })}
          {[SCREEN_CLIENT_LIST].includes(currentScreen) && JsxHelper.createButton({ label: 'Add Client', classes: 'create--btn', onClick: onClickCreateClient })}
          {currentScreen === SCREEN_CLIENT_REPORTS ? JsxHelper.createButton({ label: 'Client List', classes: 'warning--btn', onClick: () => switchScreen(SCREEN_CLIENT_LIST) }) : null}
          {currentScreen === SCREEN_CLIENT_REPORTS && hasUpdatePermissions && JsxHelper.createButton({ label: 'Create Preview', classes: 'create--btn', onClick: onClickCreatePreview })}
          {currentScreen === SCREEN_CLIENT_REPORTS && !disableLoadMore && JsxHelper.createLoadMoreButton({ loading: loadingPDFs, onClick: fetchReports, className: 'alt--btn' })}
          {currentScreen === SCREEN_CLIENT_REPORTS ? JsxHelper.createBackButton('/reports') : JsxHelper.createCloseButton({ onClick: () => switchScreen(SCREEN_CLIENT_REPORTS) })}
        </TitleBar.Actions>
      </TitleBar>
      {currentScreen === SCREEN_SETTINGS && <p className='color-primary subheader margin-top-24 margin-left-6'>
        Customize the appearance and schedule of client reports, including delivery days and pages to exclude. For additional guidance, refer to the {JsxHelper.createFaqLink('personalize-client-pdf-report-v2', 'Personalize Client PDF Report')} FAQ.
      </p>}
      <Content>
        {currentScreen === SCREEN_SETTINGS && renderGlobalSettingsTabs()}
        {currentScreen !== SCREEN_SETTINGS && <p className='subheader subheader-box padding-left-0'>{showCustomSMTPTip && JsxHelper.createTipBox(<Fragment>
          Did you know you can send emails to your clients using your own <span onClick={goToClientSMTPSettings} className='subheader-link'>own SMTP server</span>?
        </Fragment>)}</p>}
        {currentScreen === SCREEN_CLIENT_REPORTS && <WPSDataTable columns={reportsHeader} body={reportsData} loading={loadingPDFs} rowsPerPage={PAGE_LIMIT} dataKey={'guid'} />}
        {currentScreen === SCREEN_CLIENT_LIST && <WPSDataTable columns={receiversHeader} body={receiversData} loading={loadingReceivers} rowsPerPage={PAGE_LIMIT} dataKey={'guid'} />}
        {currentScreen === SCREEN_SETTINGS && renderSettings()}
      </Content>
      {modal && modal.name === 'upsert-client' && DialogHelper.inputs({
        title: `${modal.guid ? 'Edit' : 'Add'} Client`,
        icon: modal.guid ? 'edit' : 'create',
        header: <p>Assign a website to a client. The client will receive a monthly report in the beginning of each month.</p>,
        iconColor: 'success',
        btnColor: 'success',
        btnText: 'Save',
        loading: modal.loading,
        onConfirm: handleSubmit(handleAddClientSubmit),
        onClose: () => setModal(false),
        register,
        inputs: [{
          name: 'website_slug',
          type: 'select',
          label: 'Website',
          value: modal.website_slug,
          options: websitesSelectOptions,
          onChange: handleModalChange,
          required: true,
          disabled: modal.guid,
          errors,
        }, {
          name: 'client_name',
          type: 'text',
          label: 'Client Name',
          labelTooltip: 'The name does not affect the email but can be helpful for reference and inclusion in the PDF report.',
          value: modal.client_name,
          onChange: handleModalChange,
          required: true,
          errors,
        }, {
          name: 'client_emails',
          type: 'text',
          label: 'Client Emails (comma separated)',
          value: modal.client_emails,
          onChange: handleModalChange,
          required: true,
          errors,
        }]
      })}
      {modal && modal.name === 'create-preview' && DialogHelper.inputs({
        title: 'Create Preview Report',
        icon: 'create',
        iconColor: 'success',
        btnColor: 'success',
        btnText: 'Create',
        header: <p>This report is for testing only and won't notify clients. Monthly reports auto-generate from the 1st to the last day.</p>,
        loading: modal.loading,
        onConfirm: handleSubmit(handleCreatePreviewSubmit),
        onClose: () => setModal(false),
        register,
        inputs: [{
          name: 'website_slug',
          type: 'select',
          label: 'Website',
          value: modal.website_slug,
          options: websitesSelectOptions,
          onChange: handleModalChange,
          required: true,
          errors,
        }, {
          name: 'start_date',
          label: 'Start Date',
          type: 'date',
          value: modal.start_date,
          onChange: handleModalChange,
          required: true,
          errors,
        }, {
          name: 'end_date',
          label: 'End Date',
          type: 'date',
          value: modal.end_date,
          onChange: handleModalChange,
          required: true,
          errors,
        }]
      })}
      {modal && modal.name === 'send-email' && DialogHelper.inputs({
        title: 'Send Report Email',
        icon: 'email',
        iconColor: 'success',
        btnColor: 'success',
        btnText: 'Send',
        loading: modal.loading,
        header: <p>This will send the same report that the client receives monthly.</p>,
        onConfirm: handleSubmit(handleSendEmailSubmit),
        onClose: () => setModal(false),
        register,
        inputs: [{
          name: 'client_emails',
          type: 'text',
          label: 'Emails (comma separated)',
          value: modal.client_emails,
          onChange: handleModalChange,
          required: true,
          errors,
        }
      ]})}
    </Fragment>
  );
}

export default ClientReports;
