import { postRequest, deleteRequest, downloadRequest, getRequest } from '../config/axiosRequest';
import env from '../config/env';

/**
 * Refreshes the specified server.
 * 
 * @param   {Object}  data
 * @return  {Object}
 */
const refresh = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/refresh`),
    data,
  });
}

/**
 * Creates a new server.
 *
 * @param   {Object} data
 * @return  {Object}
 */
export const create = data => {
  return postRequest({
    url: env.buildApiEndpoint('servers'),
    data,
  });
};

/**
 * Update the specified server.
 *
 * @param   {Object}  data
 * @return  {Object}
 */
const update = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}`),
    data,
  });
};

/**
 * Delete the specified server.
 *
 * @param   {String}  serverSlug
 */
const del = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}`),
    data,
  });
};

/**
 * Returns the server's SSH key as a downloadable file.
 *
 * @param   {String}  serverSlug
 */
const getSSHKey = data => {
  return downloadRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/certificate`),
    data,
  });
};

/**
 * Reboots the specified server's primary instance.
 *
 * @param   {String}  serverSlug
 */
const reboot = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/reboot`),
    data,
  });
};

/**
 * Starts the specified server's primary instance.
 *
 * @param   {String}  serverSlug
 */
const start = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/start`),
    data,
  });
};

/**
 * Stops the specified server's primary instance.
 *
 * @param   {String}  serverSlug
 */
const stop = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/stop`),
    data,
  });
};

/**
 * Save a global server option
 *
 * @param   {Object}  data
 * @param   {string}  optionName
 */
export const saveGlobalServerOption = (data, optionName) => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/options/${optionName}`),
    data,
  });
};

/**
 * Updates the specified server's instance as the primary node.
 *
 * @param   {String}  serverSlug
 */
const setPrimaryInstance = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/instances/${data.instance_id}/primary`),
    data,
  });
};

// List server instances
const listInstances = data => {
  return getRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/instances`),
    data,
  });
};


// Clone a server instance
const cloneInstance = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/instances/${data.instance_id}/clone`),
    data,
  });
};

// Reboot a server instance
const rebootInstance = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/instances/${data.instance_id}/reboot`),
    data,
  });
};

// Sync secondary server instance.
const syncInstance = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/instances/${data.instance_id}/sync`),
    data,
  });
};

// Delete a server instance
const deleteInstance = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/instances/${data.instance_id}`),
    data,
  });
};

// Stop a server instance
const startInstance = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/instances/${data.instance_id}/start`),
    data,
  });
};

// Stop a server instance
const stopInstance = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/instances/${data.instance_id}/stop`),
    data,
  });
};

// Sync EWSM code.
const syncEWSMCode = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/sync-ewsm-code`),
    data,
  });
};

// Updates server config
const updatePhpConfig = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/php-config`),
    data,
  });
};

// Get OPcache stats
const getOPcacheStats = data => {
  return getRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/opcache-stats`),
    data,
  });
};

// Get access stats
const getAccessStats = data => {
  return getRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/access-stats`),
    data,
  });
};

// Get redis stats
const getRedisStats = data => {
  return getRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/redis-stats`),
    data,
  });
};

// Resize a server's EBS volumes.
const resizeVolumes = data => {
  return postRequest({
    url: env.buildApiEndpoint(`servers/${data.server_slug}/resize-volume`),
    data,
  });
};

// Fetch sync data status.
const syncDataStatus = data => {
  return postRequest({
    url: env.buildApiEndpoint(
      `servers/${data.server_slug}/instances/${data.instance_id}/validate-sync`,
    ),
    data,
  });
};

const ServerService = {
  refresh,
  create,
  update,
  delete: del,
  getSSHKey,
  syncEWSMCode,
  reboot,
  stop,
  start,
  setPrimaryInstance,
  listInstances,
  cloneInstance,
  rebootInstance,
  syncInstance,
  deleteInstance,
  stopInstance,
  startInstance,
  resizeVolumes,
  syncDataStatus,
  updatePhpConfig,
  getOPcacheStats,
  getAccessStats,
  getRedisStats,
};

export default ServerService;
